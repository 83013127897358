/* eslint-disable radix */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import isEqual from 'lodash.isequal';
import NumberInput from '../NumberInput';
import Validators, { ValidationResult } from '../../../utils/validators';
import './index.scss';

interface DateInputProps {
  value?: string;
  disabled?: boolean;
  className?: string;
  validation?: ValidationResult;
  onChange?: (val: string) => void;
  onError?: (validation: ValidationResult) => void;
}

interface DateState {
  day: string;
  month: string;
  year: string;
}

const initialState: DateState = {
  day: '',
  month: '',
  year: '',
};

const DateInput = ({
  value,
  disabled,
  className,
  validation,
  onChange,
  onError,
}: DateInputProps) => {
  const [date, setDate] = useState(initialState);
  const [validations, setValidation] = useState<ValidationResult>({
    valid: true,
    message: '',
  });
  const [focus, setFocus] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const prevPropsValidation = useRef<ValidationResult>();
  const monthRef = useRef<HTMLInputElement>();
  const yearRef = useRef<HTMLInputElement>();
  const dateRef = useRef<DateState>();
  const firstPropsUpdate = useRef(false);

  const leadingZero = (dateInput: number | string): string => {
    if (dateInput) {
      const parsedDate = typeof dateInput === 'string' ? parseInt(dateInput) : dateInput;
      return parsedDate > 0 && parsedDate < 10 ? `0${parsedDate}` : parsedDate.toString();
    }
    return '';
  };

  const modifyYear = (year: string): string => {
    if (year) {
      if (year.length === 1) {
        return `200${year}`;
      }
      if (year.length === 2) {
        return `19${year}`;
      }
      return year;
    }
    return '';
  };

  useEffect(() => {
    if (date.day && date.month && date.year) {
      const dateInput = `${date.year}-${date.month}-${date.day}`;

      const dateValidation =
        firstPropsUpdate.current && validation && !validation.valid
          ? validation
          : Validators.dateValidator().validate(dateInput);
      firstPropsUpdate.current = false;

      setValidation(dateValidation);
      if (dateValidation?.valid) {
        if (onChange) {
          onChange(dateInput);
        }
        if (onError) {
          onError({ valid: true });
        }
      } else {
        if (onChange) {
          onChange('');
        }
        if (onError) {
          onError(dateValidation);
        }
      }
    } else if ((date.day || date.month || date.year) && focus) {
      if (monthRef.current && date.day?.length === 2 && date.month?.length !== 2) {
        monthRef.current.focus();
      } else if (
        yearRef.current &&
        date.day?.length === 2 &&
        date.month?.length === 2 &&
        !date.year?.length
      ) {
        yearRef.current.focus();
      }

      const incompleteValidation = {
        valid: false,
        message: 'Bitte geben Sie ein gültiges Datum ein',
      };
      setValidation(incompleteValidation);
      if (onError) {
        onError(incompleteValidation);
      }
    }
  }, [date]);

  useEffect(() => {
    if ((date.day || date.month || date.year) && !focus && !isEqual(dateRef.current, date)) {
      const newDate = {
        day: leadingZero(date.day),
        month: leadingZero(date.month),
        year: modifyYear(date.year),
      };
      dateRef.current = newDate;
      setDate(newDate);
    }
  }, [focus]);

  useEffect(() => {
    if (validations?.valid || focus) {
      setShowErrors(false);
    } else {
      setShowErrors(true);
    }
  }, [focus, validations]);

  useEffect(() => {
    const propsDate = value;
    if (propsDate) {
      firstPropsUpdate.current = true;
      const dateValidation = Validators.dateValidator().validate(propsDate);
      if (dateValidation.valid) {
        const dateMoment = moment(propsDate, 'YYYY-MM-DD');
        setDate({
          ...date,
          day: leadingZero(dateMoment.date()),
          month: leadingZero(dateMoment.month() + 1),
          year: dateMoment.year().toString(),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (validation && !isEqual(prevPropsValidation.current, validation)) {
      prevPropsValidation.current = validation;
      setValidation(validation);
    }
  }, [validation]);

  return (
    <div className={`date-input ${showErrors ? 'error ' : ''} ${className ?? ''}`}>
      <div className='input-area'>
        <NumberInput
          className='day-input'
          value={date.day}
          placeholder='TT'
          disabled={disabled}
          onChange={(v) => setDate({ ...date, day: v })}
          min={1}
          max={31}
          onFocusChange={setFocus}
        />
        <NumberInput
          className='month-input'
          value={date.month}
          placeholder='MM'
          disabled={disabled}
          onChange={(v) => setDate({ ...date, month: v })}
          min={1}
          max={12}
          onFocusChange={setFocus}
          inputRef={monthRef}
        />
        <NumberInput
          className='year-input'
          value={date.year}
          placeholder='JJJJ'
          disabled={disabled}
          onChange={(v) => setDate({ ...date, year: v })}
          min={0}
          onFocusChange={setFocus}
          inputRef={yearRef}
        />
      </div>
      {showErrors && <div className='error'> {validations?.message} </div>}
    </div>
  );
};

export default DateInput;
