import React from 'react';
import './index.scss';
import { ReactComponent as CarretIcon } from '../../../assets/carret.svg';

type ResetButtonProps = {
  onClick: () => void;
  title: string;
};

const ResetButton = ({ onClick, title }: ResetButtonProps) => (
  <div onClick={onClick} className='reset-button' data-testid='reset-button'>
    <CarretIcon className='rotate-270' />
    {title}
  </div>
);

export default ResetButton;
