import React, { useState, useEffect } from 'react';
import './index.scss';

import ContactCard from '../../../Common/ContactCard';
import Container from '../../../Common/Container';
import Checkbox from '../../../Common/Checkbox';
import Transaction from './components/Transaction';
import { ValidationResult } from '../../../../utils/validators';
import { ReactComponent as CarretIcon } from '../../../../assets/carret.svg';
import { useKwsState } from '../../../../contexts/KwsStateContext/KwsStateContext';
import DateInput from '../../../Common/DateInput';
import ServiceGateway from '../../../../services/ServiceGateway';
import { formatDate, sort, scrollToPosition } from '../../../../utils/helper';
import { ItemState } from '../../../../types/Overview/Common';
import { useOverview } from '../../../../contexts/OverviewContext/OverviewContext';
import { NotificationModel } from '../../../../types/Overview/NotificationModel';
import StickyButtons from '../../../Common/StickyButtons';
import InfoBox from '../../../Common/InfoBox';
import { StandingOrderModel } from '../../../../types/Overview/StandingOrderModel';

const SwitchingDateDetails = () => {
  const { kwsState, setSwitchingDateToProcess } = useKwsState();
  const { overviewData } = useOverview();
  const overviewDataCheck = overviewData!;

  const [showTransactions, setShowTransactions] = useState(false);
  const [validationError, setValidationError] = useState<Record<string, ValidationResult>>({});

  const notifyNotifications = overviewDataCheck.notifications.filter(
    (x: NotificationModel) => x.state === ItemState.NOTIFY,
  );
  const notifySO = overviewDataCheck.standingOrders.filter(
    (x: StandingOrderModel) => x.state === ItemState.NOTIFY,
  );

  const sortedNotifyItems = sort([...notifyNotifications, ...notifySO], 'date');

  const [globalDateState, setGlobalDateState] = useState(
    overviewDataCheck.globalDate.globalSwitchingDate,
  );
  const [asapState, setAsapState] = useState(overviewDataCheck.globalDate.atLeastOneActiveAsap);

  const [loading, setLoading] = useState(false);

  const submit = async () => {
    setLoading(true);
    try {
      const data = {
        globalSwitchingDate: globalDateState,
        asap: asapState,
      };
      const result = await ServiceGateway.editSwitchingDate(kwsState!.id, data);
      const isSuccess = result.status >= 200 && result.status < 300;
      if (isSuccess) {
        setSwitchingDateToProcess(false);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        if (error.response?.data?.errors) {
          setValidationError({
            ...validationError,
            ...error.response.data.errors.reduce((acc: any, err: any) => {
              if (err.type === 'FIELD') {
                return {
                  ...acc,
                  globalDate: { valid: false, message: err.message },
                };
              }
              return acc;
            }, {}),
          });
        }
      }
    }
    setLoading(false);
  };

  const onCheck = (checked: boolean) => {
    if (checked) {
      setGlobalDateState(overviewDataCheck.globalDate.earliestSwitchingDate);
    }
    setAsapState(checked);
    setValidationError({});
  };

  const goBack = () => {
    setSwitchingDateToProcess(false);
  };

  const errorCheck = !(
    globalDateState && Object.values(validationError).every((item) => item.valid)
  );

  useEffect(() => {
    scrollToPosition('0');
  }, []);

  return (
    <div className='switchingdate-details'>
      <Container>
        <div className='switchingdate-transaction'>
          <div className='row-1'>
            <div className='col-2'>
              <div className='name'>Kontoumzugsdatum</div>
            </div>
            <div className='col-3'>
              <div className='amount'>
                {overviewDataCheck.globalDate.allSwitchingDatesMatch ? 'zum' : 'ab'}{' '}
                {formatDate(overviewDataCheck.globalDate.globalSwitchingDate)}
              </div>
              <div className='frequency'>
                {overviewDataCheck.globalDate.allSwitchingDatesMatch
                  ? 'alle umstellen'
                  : 'teilweise umstellen'}
              </div>
            </div>
          </div>
          <div className='toggle' onClick={() => setShowTransactions(!showTransactions)}>
            <div>mehr</div>
            <CarretIcon className={showTransactions ? 'open' : ''} />
          </div>
          {showTransactions && (
            <div className='transactions'>
              {sortedNotifyItems.map((t: NotificationModel | StandingOrderModel) => (
                <Transaction
                  title={'normalizedCounterName' in t ? t.normalizedCounterName : t.recipientName}
                  subtext={'description' in t ? t.description : t.categoryLocale}
                  key={t.id}
                  date={t.dates.switchingDate}
                />
              ))}
              {overviewDataCheck.closingData.state === ItemState.NOTIFY && (
                <Transaction
                  title='Kontoschließung'
                  subtext={`Konto ${overviewDataCheck.closingData.oldBank.name} ${overviewDataCheck.closingData.oldBank.iban}`}
                  date={overviewDataCheck.closingData.accountClosingDate}
                  color='#000'
                />
              )}
            </div>
          )}
        </div>
      </Container>

      {/* Form */}
      <div className='form-container'>
        <div className='description'>
          Sie können den Stichtag frei wählen, ab dem Ihre Zahlungspartner Ihr neues Konto nutzen
          sollen.
        </div>

        {/* New Account */}
        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Kontoumzug zum</div>
          </div>
          <div className='asap-row'>
            <Checkbox onChange={onCheck} checked={asapState} inputId='switching-date' />
            <div className='asap-text'>Schnellstmöglich</div>
          </div>
          <div className='form-input'>
            <DateInput
              value={globalDateState}
              onChange={(v) => setGlobalDateState(v)}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  globalDate: e,
                }))
              }
              validation={validationError.globalDate}
              disabled={asapState}
            />
          </div>
          <InfoBox type='info'>
            <p>
              CHECK24 informiert Ihre Zahlungspartner rechtzeitig über Ihre neue Bankverbindung. In
              der Regel benötigen Zahlungspartner eine gewisse Zeit, um Ihren Kontoumzug zu
              bestätigen. Ein früheres Umzugsdatum ist deshalb nicht auswählbar.
            </p>
          </InfoBox>
        </div>
      </div>

      <StickyButtons
        isDisabled={errorCheck}
        isLoading={loading}
        proceedButton={{ label: 'speichern', onClick: submit }}
        cancelButton={{ label: 'Änderungen verwerfen', onCancel: goBack }}
      />

      <ContactCard />
    </div>
  );
};

export default SwitchingDateDetails;
