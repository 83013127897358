import React from 'react';
import Notification from './Notification';
import {
  NotificationModel,
  NotificationType,
} from '../../../../../types/Overview/NotificationModel';
import { ItemState } from '../../../../../types/Overview/Common';
import InnerBorderContainer from '../../../../Common/InnerBorderContainer';

interface NotificationCategoryProps {
  notifications: NotificationModel[];
}

const NotificationCategory = ({ notifications }: NotificationCategoryProps) => {
  const categoryToShow = notifications?.filter(
    (notification: NotificationModel) =>
      notification.type !== NotificationType.RELEVANT && notification.state !== ItemState.NOTIFY,
  );
  const categoryToShowActive = categoryToShow?.filter(
    (notification: NotificationModel) => !notification.inactive,
  );
  const categoryToShowInactive = categoryToShow?.filter(
    (notification: NotificationModel) => notification.inactive,
  );

  return (
    <div className='partner'>
      <div className='header'>
        <h2>{notifications[0]?.categoryLocale}</h2>
      </div>
      <div className='notifications'>
        <InnerBorderContainer>
          {categoryToShowActive.map((t: any) => (
            <Notification key={`${t.id}-partner`} notification={t} />
          ))}
          {categoryToShowInactive.map((t: any) => (
            <Notification key={`${t.id}-partner`} notification={t} />
          ))}
        </InnerBorderContainer>
      </div>
    </div>
  );
};

export default NotificationCategory;
