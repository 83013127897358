import React from 'react';
import './index.scss';

import Container from '../Container/index';
import { ReactComponent as PhoneIcon } from '../../../assets/phone.svg';
import { ReactComponent as AtIcon } from '../../../assets/at.svg';
import { useKwsState } from '../../../contexts/KwsStateContext/KwsStateContext';
import InnerBorderContainer from '../InnerBorderContainer';

const ContactCard = () => {
  const { kwsState } = useKwsState();

  return (
    <Container>
      <div className='contact-card'>
        <h2>Haben Sie Fragen?</h2>
        <div className='contact-item'>
          <div>
            Unser Experten-Team für den CHECK24 Kontoumzug berät Sie gerne.
            {kwsState?.processNumber &&
              ` Geben Sie bei Rückfragen bitte Ihre Vorgangsnummer
            ${kwsState!.processNumber} an.`}
          </div>
        </div>
        <div className='contact-item'>
          <div>
            <div>Sie erreichen uns</div>
          </div>
          <div>Montag bis Freitag | 08:00 - 17:00 Uhr</div>
        </div>
        <div className='contact-item'>
          <InnerBorderContainer>
            <a href='tel:+498924241109' target='_blank' rel='noreferrer' className='contact-block'>
              <PhoneIcon />
              <div>
                <div className='text'>Ihr persönlicher Kontakt zu uns </div>
                <div className='subtext'>089 24241109</div>
              </div>
            </a>
          </InnerBorderContainer>
        </div>
        <div className='contact-item'>
          <InnerBorderContainer>
            <a
              href='mailto:kontoumzug@check24.de'
              target='_blank'
              rel='noreferrer'
              className='contact-block'
            >
              <AtIcon />
              <div>
                <div className='text'>Anfrage per E-Mail</div>
                <div className='subtext'>kontoumzug@check24.de</div>
              </div>
            </a>
          </InnerBorderContainer>
        </div>
      </div>
    </Container>
  );
};

export default ContactCard;
