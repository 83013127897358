import React, { useEffect, useRef, useState } from 'react';
import guarantee from '../../../assets/guarantee.png';
import { ReactComponent as CheckIcon } from '../../../assets/check.svg';
import './index.scss';

interface ProgressBarProps {
  expectedWaitingTimeInSeconds?: number;
  done: boolean;
}

let interval: ReturnType<typeof setInterval>;

const ProgressBar = (props: ProgressBarProps): JSX.Element => {
  const { expectedWaitingTimeInSeconds, done } = props;
  const [processPercentage, setProcessPercentage] = useState(0);
  const progressRef = useRef<HTMLDivElement>(null);

  const completeProcess = () => {
    clearInterval(interval);
    setProcessPercentage(100);
    progressRef.current!.style.transition = 'none';
    progressRef.current!.style.width = '100%';
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (expectedWaitingTimeInSeconds !== undefined) {
      if (expectedWaitingTimeInSeconds === 0) {
        completeProcess();
      } else if (expectedWaitingTimeInSeconds > 0) {
        clearInterval(interval);

        progressRef.current!.style.width = '0%';
        progressRef.current!.style.transition = `width ${
          // here we get 9/10 of given time, because we fill 90 percent of the bar with given time.
          expectedWaitingTimeInSeconds * 0.9
        }s linear`;

        timeout = setTimeout(() => {
          progressRef.current!.style.width = '90%';

          interval = setInterval(() => {
            setProcessPercentage((p) => p + 1);
            // here we calculate 1/100 of given waiting time.
          }, 10 * expectedWaitingTimeInSeconds);
        }, 1000);
      }
    }

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [expectedWaitingTimeInSeconds]);

  useEffect(() => {
    progressRef.current!.style.width = processPercentage.toString();
    if (processPercentage === 90) {
      clearInterval(interval);
    }
  }, [processPercentage]);

  useEffect(() => {
    if (done) {
      completeProcess();
    }
  }, [done]);

  return (
    <div className='progress-bar' data-testid='progress-bar'>
      <div className='loader-mask'>
        <div className='loader-wrapper'>
          <div className='loader-container'>
            <div className='progress-message'>Wir bereiten den Kontoumzug für Sie vor …</div>
            <div className='progress-wrapper'>
              <div className='progress'>
                <div ref={progressRef} className='progress-bar-loader' />
              </div>
              <div className='progress-percent'>{`${processPercentage}%`}</div>
            </div>
            <div className='progress-row'>
              <div className='process-list'>
                <div className='list-item'>
                  <CheckIcon className={`check-icon${processPercentage >= 40 ? ' checked' : ''}`} />
                  <p>Relevante Zahlungspartner werden erkannt</p>
                </div>
                <div className='list-item'>
                  <CheckIcon className={`check-icon${processPercentage >= 70 ? ' checked' : ''}`} />
                  <p>Daueraufträge werden abgerufen</p>
                </div>
                <div className='list-item'>
                  <CheckIcon className={`check-icon${processPercentage >= 90 ? ' checked' : ''}`} />
                  <p>Frühestmögliches Umzugsdatum wird berechnet</p>
                </div>
              </div>
              <img src={guarantee} alt='' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
