import React, { useEffect, useRef, useState } from 'react';
import isEqual from 'lodash.isequal';
import { ValidationResult, Validator } from '../../../utils/validators';
import './index.scss';
import { scrollToTopForMobileAndApp } from '../../../utils/helper';

interface NumberInputProps {
  placeholder: string;
  validator?: Validator;
  validation?: ValidationResult;
  icon?: any;
  disabled?: boolean;
  className?: string;
  value: string;
  min?: number;
  max?: number;
  inputRef?: any;
  onChange?: (v: string) => void;
  onFocusChange?: (focus: boolean) => void;
  onError?: (validation: ValidationResult) => void;
}

interface NumberInputState {
  focus: boolean;
  value: string;
  validation: ValidationResult;
}

const initialState: NumberInputState = {
  focus: false,
  value: '',
  validation: {
    valid: true,
    message: '',
  },
};

const NumberInput = (props: NumberInputProps) => {
  const {
    placeholder,
    value,
    validation,
    validator,
    icon,
    inputRef,
    disabled,
    className,
    min,
    max,
    onChange,
    onError,
    onFocusChange,
  } = props;
  const [state, setState] = useState(initialState);
  const [showErrors, setShowErrors] = useState(false);
  const prevPropsValidation = useRef<ValidationResult>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueV = e.currentTarget.value || '';
    const validationV = validator?.validate(valueV);
    setState({
      ...state,
      value: valueV,
      validation: validationV ?? initialState.validation,
    });
    if (onChange) {
      onChange(valueV);
    }
    if (onError && validationV) {
      if (!validationV.valid) {
        onError(validationV);
      } else {
        onError({ valid: true });
      }
    }
  };

  const preventScrollChangeInputField = (e: React.WheelEvent<HTMLInputElement>) => {
    const inputElement = e.currentTarget;
    inputElement.blur();

    setTimeout(() => {
      inputElement.focus();
    }, 0);
  };

  useEffect(() => {
    if (state.validation?.valid || state.focus) {
      setShowErrors(false);
    } else {
      setShowErrors(true);
    }
  }, [state.focus, state.validation]);

  useEffect(() => {
    if (value) {
      setState({
        ...state,
        value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (validation && !isEqual(prevPropsValidation.current, validation)) {
      prevPropsValidation.current = validation;
      setState({
        ...state,
        validation,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation]);

  useEffect(() => {
    if (state.value) {
      const validationV = validator?.validate(state.value);
      if (validationV && !validationV.valid) {
        setState({ ...state, validation: validationV });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value]);

  return (
    <div
      className={`text-input ${showErrors ? 'error ' : ''}${state.focus ? 'focus ' : ''}${
        disabled ? 'disabled ' : ''
      }${className ?? ''}`}
      data-testid='number-input'
    >
      <div className='inner'>
        <input
          className={state.value ? 'has-value' : ''}
          type='number'
          inputMode='numeric'
          value={state.value}
          disabled={disabled}
          onChange={handleChange}
          min={min}
          max={max}
          onWheel={preventScrollChangeInputField}
          onFocus={(e) => {
            setState({ ...state, focus: true });
            if (onFocusChange) {
              onFocusChange(true);
            }
            scrollToTopForMobileAndApp(window.scrollY, e.target.getBoundingClientRect().y);
          }}
          onBlur={() => {
            setState({ ...state, focus: false });
            if (onFocusChange) {
              onFocusChange(false);
            }
          }}
          ref={inputRef}
          data-testid='number-input-field'
        />
        <div className='floating-label'>{placeholder}</div>
        {icon && (
          <div className='append-inner'>
            <img src={icon} alt='' />
          </div>
        )}
      </div>
      {showErrors && <div className='error'> {state.validation?.message} </div>}
    </div>
  );
};

export default NumberInput;
