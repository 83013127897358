import React from 'react';
import { formatAmount, formatDate, removeNegativeSign } from '../../../../../../../utils/helper';
import { NotificationModel } from '../../../../../../../types/Overview/NotificationModel';
import { StandingOrderModel } from '../../../../../../../types/Overview/StandingOrderModel';
import './index.scss';

interface ReviewTransactionProps {
  reviewTransaction: NotificationModel | StandingOrderModel;
}

const ReviewTransaction = ({ reviewTransaction }: ReviewTransactionProps) => {
  let amount: number | null;
  let description: string;
  let name: string;

  if ('normalizedCounterName' in reviewTransaction) {
    amount = reviewTransaction.amount;
    name = reviewTransaction.normalizedCounterName;
    description = reviewTransaction.categoryLocale;
  } else {
    name = reviewTransaction.recipientName;
    amount = -1 * reviewTransaction.amount;
    description = reviewTransaction.description;
  }

  const switchingDate = reviewTransaction?.dates?.switchingDate;

  return (
    <div className='review-transaction'>
      <div className='data'>
        <div data-testid='revew-transaction-name' className='name'>
          {name}
        </div>
        <div data-testid='review-transaction-info' className='info'>
          {description}
        </div>
      </div>
      <div className='extra-info'>
        {amount ? (
          <div
            data-testid='review-transaction-amount'
            className={`amount ${amount > 0 ? 'positive' : 'negative'}`}
          >
            {`${amount > 0 ? '+' : '-'}${removeNegativeSign(formatAmount(amount.toString()))} €`}
          </div>
        ) : null}
        {switchingDate && (
          <div data-testid='review-trasaction-switching-date' className='date'>
            zum {formatDate(switchingDate)}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewTransaction;
