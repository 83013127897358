import React, { useCallback, useMemo, useState } from 'react';
import { KwsStateContext } from './KwsStateContext';
import { DetailPage, IKwsState, IKwsStateProvider } from './KwsState.types';
import ServiceGateway from '../../services/ServiceGateway';

const KwsStateProvider: React.FC<Partial<IKwsStateProvider>> = ({
  kwsState: kwsStateProps,
  notificationToProcess: notificationToProcessMock,
  standingOrderToProcess: standingOrderToProcessMock,
  setNotificationToProcess: setNotificationToProcessMock,
  setStandingOrderToProcess: setStandingOrderToProcessMock,
  setAccountToProcess: setAccountToProcessMock,
  setSwitchingDateToProcess: setSwitchingDateToProcessMock,
  children,
}) => {
  const [kwsState, setKwsState] = useState<IKwsState | undefined>(kwsStateProps);
  const [notificationToProcess, setNotificationToProcess] = useState<DetailPage | undefined>();
  const [standingOrderToProcess, setStandingOrderToProcess] = useState<DetailPage | undefined>();
  const [accountToProcess, setAccountToProcess] = useState(false);
  const [switchingDateToProcess, setSwitchingDateToProcess] = useState(false);

  const refreshKwsState = useCallback(
    (id?: string, onSuccess?: () => void) => {
      ServiceGateway.getProcessState(id || kwsState!.id).then((result) => {
        setKwsState({
          id: id || kwsState!.id,
          state: result.data.processState,
          processNumber: result.data.processNumber,
          workflow: result.data.workflow,
          invalidSwitchingDates: result.data.invalidSwitchingDates,
          transactionTimeframe: result.data.transactionTimeframe,
          archived: result.data.archived,
          ibanSource: result.data.ibanSource,
        });
        if (onSuccess) {
          onSuccess();
        }
      });
    },
    [kwsState],
  );

  const initialProvider: IKwsStateProvider = useMemo(
    () => ({
      kwsState,
      notificationToProcess: notificationToProcessMock || notificationToProcess,
      standingOrderToProcess: standingOrderToProcessMock || standingOrderToProcess,
      accountToProcess,
      switchingDateToProcess,
      refreshKwsState,
      setNotificationToProcess: setNotificationToProcessMock || setNotificationToProcess,
      setStandingOrderToProcess: setStandingOrderToProcessMock || setStandingOrderToProcess,
      setAccountToProcess: setAccountToProcessMock || setAccountToProcess,
      setSwitchingDateToProcess: setSwitchingDateToProcessMock || setSwitchingDateToProcess,
    }),
    [
      kwsState,
      notificationToProcess,
      standingOrderToProcess,
      accountToProcess,
      switchingDateToProcess,
      refreshKwsState,
      setNotificationToProcessMock,
      setStandingOrderToProcessMock,
      setAccountToProcessMock,
      setSwitchingDateToProcessMock,
    ],
  );

  return <KwsStateContext.Provider value={initialProvider}>{children}</KwsStateContext.Provider>;
};

export default KwsStateProvider;
