import React from 'react';
import './index.scss';

interface ContainerProps {
  children?: React.ReactNode;
}

const Container = ({ children }: ContainerProps): JSX.Element => (
  <div className='container'>{children}</div>
);

export default Container;
