import React, { useEffect, useState } from 'react';
import { useKwsState } from '../../../../contexts/KwsStateContext/KwsStateContext';
import ServiceGateway from '../../../../services/ServiceGateway';
import Intro from '../../CommonSections/Intro';
import { scrollToPosition } from '../../../../utils/helper';
import { StandingOrderDetailsModel } from '../../../../types/Overview/StandingOrderModel';
import SpinnerFullPage from '../../../Common/SpinnerFullPage';
import StandingOrderDetailTab from './components/StandingOrderDetailTab';
import AllTransactionsTab from '../../CommonSections/AllTransactionsTab';
import TabButtons from '../../../Common/TabButtons';

const StandingOrderDetails = () => {
  const { kwsState, standingOrderToProcess } = useKwsState();
  const { pageType, id: soId } = standingOrderToProcess!;
  const [standingOrderDetailState, setStandingOrderDetailState] =
    useState<StandingOrderDetailsModel>();

  const getStandingOrder = async () => {
    switch (pageType) {
      case 'EDIT': {
        const response = await ServiceGateway.getStandingOrder(kwsState!.id, soId!);
        setStandingOrderDetailState(response.data);
        break;
      }
      case 'ADD': {
        const response = await ServiceGateway.preCreateStandingOrder(kwsState!.id);
        setStandingOrderDetailState(response.data);
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    scrollToPosition('0');
    getStandingOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!standingOrderDetailState) {
    return <SpinnerFullPage />;
  }

  const tabData = [
    {
      id: 0,
      title: 'Dauerauftrag',
      content: (
        <StandingOrderDetailTab
          standingOrderDetailState={standingOrderDetailState}
          setStandingOrderDetailState={setStandingOrderDetailState}
        />
      ),
    },
    ...(standingOrderDetailState.transactions && standingOrderDetailState.transactions?.length !== 0
      ? [
          {
            id: 1,
            title: 'Transaktionen',
            content: <AllTransactionsTab transactions={standingOrderDetailState!.transactions!} />,
          },
        ]
      : []),
  ];

  return (
    <div className='standing-orders-details' data-testid='standing-orders-details'>
      {/* Item Info */}
      {pageType === 'EDIT' && (
        <Intro
          data={standingOrderDetailState}
          pageType='standingOrder'
          classNames={tabData.length > 1 ? 'bottom-border' : ''}
        />
      )}

      {tabData.length > 1 ? <TabButtons tabs={tabData} classNames='sticky' /> : tabData[0].content}
    </div>
  );
};

export default StandingOrderDetails;
