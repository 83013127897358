import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import './index.scss';

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import Cookies from 'js-cookie';
import App from './components/App';
import NotPossible from './components/NotPossible';
import KwsBody from './components/App/KwsBodyWrapper';
import { EnvironmentEnum, isProd } from './utils/environment';

declare global {
  interface Window {
    wf_sso_type: string;
    wf_deviceoutput: string;
    Check24: any;
    C24M: any;
    xs2a: any;
  }
}

Sentry.init({
  dsn: 'https://63ff5aa5e8c60c1f4624c26479d8d2f6@o4505237834301440.ingest.us.sentry.io/4506575212380160',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0.1,
  environment: process.env.REACT_APP_ENV || EnvironmentEnum.LOCAL,
  ignoreErrors: [
    'Network Error',
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'Request failed with status code 400',
    'Request failed with status code 401',
    'Request failed with status code 406',
    'Request failed with status code 410',
  ],
});

const urlParams = new URLSearchParams(window.location.search);
const processId = urlParams.get('id');
if (processId) {
  Sentry.setTag('processId', processId);
}

const unifiedLoginEvent = (e: any) => {
  const { SSO_SESSION, LOGINTYPE, SSO_ENV } = e.detail?.ul || {};

  if (SSO_SESSION) {
    // wireframe sets httponly cookie on staging. But for local and int we need to set cookie manually
    if (SSO_ENV === 'test' && process.env.REACT_APP_ENV !== EnvironmentEnum.STAGING) {
      Cookies.set('c24session', SSO_SESSION, {
        path: '/',
      });
    }
    window.location.reload();
  } else if (LOGINTYPE === 'login' || LOGINTYPE === 'register') {
    window.location.reload();
  } else {
    // eslint-disable-next-line no-console
    console.log('No sso session in unified login event', e);
  }
};

window.C24M?.cookieBanner?.init();
window.Check24?.cookieBanner?.init();

window.addEventListener('c24consent', () => {
  if (window.location.hostname.indexOf('localhost') >= 0) {
    document.cookie = 'c24consent=fam';
  }
});

if (!isProd() && window.Check24?.uliloginlayer?.config?.env) {
  window.Check24.uliloginlayer.config.env = 'test';
}
window.Check24?.uliloginlayer.init();

// for WF unified login
window.addEventListener('uli-login-layer', unifiedLoginEvent);
// for standalone unified login
window.addEventListener('unified-login', unifiedLoginEvent);

document.body.dataset.deviceoutput = window.wf_deviceoutput?.toLowerCase();

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

const rootReact = createRoot(rootElement);
rootReact.render(
  <Sentry.ErrorBoundary
    fallback={() => (
      <KwsBody>
        <NotPossible />
      </KwsBody>
    )}
  >
    <App />
  </Sentry.ErrorBoundary>,
);
