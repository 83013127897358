import React from 'react';
import './index.scss';
import { ReactComponent as CarretIcon } from '../../../assets/carret.svg';

interface AlertCardProps {
  children?: React.ReactNode;
  title?: string;
  show?: boolean;
  onChange: () => void;
}

const AlertCardV2 = ({ children, title, show, onChange }: AlertCardProps) => (
  <div className='alert-card-v2'>
    <div className='alert-header' onClick={onChange}>
      <p className={`alert-title ${show && 'bold'}`}>{title}</p>
      <CarretIcon className={`carret ${show ? 'open' : ''}`} />
    </div>
    <div className='alert-body'>{show && children}</div>
  </div>
);

export default AlertCardV2;
