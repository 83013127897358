import React from 'react';
import { formatDate } from '../../../../../../../utils/helper';
import './index.scss';

interface AccountClosingData {
  accountClosingDate: string;
  oldBankIban?: string;
  newBankName?: string;
}

const AccountClosing = ({ accountClosingDate, oldBankIban, newBankName }: AccountClosingData) => (
  <div className='account-closing'>
    <div className='transaction'>
      <div className='data'>
        <div className='name'>Kontoschließung</div>
        {oldBankIban && <div className='info'>{oldBankIban}</div>}
      </div>
      <div className='extra-info'>
        <div className='date'>zum {formatDate(accountClosingDate)}</div>
      </div>
    </div>
    <div className='transaction'>
      <div className='data'>
        <div className='name'>Überweisung Restbetrag</div>
        {newBankName && <div className='info'>auf neues {newBankName} Konto</div>}
      </div>
      <div className='extra-info'>
        <div className='date'>zum {formatDate(accountClosingDate)}</div>
      </div>
    </div>
  </div>
);

export default AccountClosing;
