import React from 'react';
import { formatDate } from '../../../../../../utils/helper';
import { useOverview } from '../../../../../../contexts/OverviewContext/OverviewContext';
import './index.scss';

interface TransactionProps {
  title?: string;
  subtext?: string;
  date: string;
  color?: string;
}

const Transaction = ({ title, subtext, date, color }: TransactionProps) => {
  const { overviewData } = useOverview();
  const { globalDate } = overviewData!;

  return (
    <div className='edit-details-transaction'>
      <div className='transaction-body'>
        <div className='left'>
          <div className='name'>{title}</div>
          <div className='date'>{subtext}</div>
        </div>
        <div className='right'>
          <div
            className='amount'
            style={{
              color:
                color ||
                (new Date(globalDate.globalSwitchingDate).valueOf() >= new Date(date).valueOf()
                  ? '#7ab51d'
                  : '#f07c00'),
            }}
          >
            {formatDate(date)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transaction;
