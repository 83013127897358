import React, { useState } from 'react';
import Button from '../../../../Common/Button';
import Modal from '../../../../Common/Modal';
import './index.scss';
import ServiceGateway from '../../../../../services/ServiceGateway';
import { useKwsState } from '../../../../../contexts/KwsStateContext/KwsStateContext';
import ResetButton from '../../../../Common/ResetButton';

const ResetSection = () => {
  const { kwsState, refreshKwsState } = useKwsState();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const submit = () => {
    if (!loading) {
      setLoading(true);
      ServiceGateway.resetProcess(kwsState!.id).then(() => {
        refreshKwsState();
        setLoading(false);
      });
    }
  };

  return (
    <div className='reset-section'>
      <ResetButton onClick={() => setShowModal(true)} title='zurück zum Login' />

      {showModal && (
        <div className='reset-modal-wrapper' data-testid='reset-modal-wrapper'>
          <Modal onClose={() => setShowModal(false)}>
            <div className='reset-modal'>
              <div className='content'>
                <p className='title'>Kontoumzug neu starten?</p>
                <p className='sub-title-1'>
                  Über den Button “neu starten” gelangen Sie zurück zum Login und können den
                  automatischen Kontoumzug mit Online-Banking wählen.
                </p>
                <p>
                  <b>Hinweis:</b> Ihre bisherigen Eingaben werden gelöscht.
                </p>
              </div>

              <div className='desktop-buttons'>
                <Button
                  classNames='secondary'
                  onClick={() => setShowModal(false)}
                  label='abbrechen'
                  isDisabled={loading}
                />
                <Button onClick={submit} label='neu starten' loading={loading} />
              </div>
              <div className='mobile-button' onClick={submit}>
                neu starten
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default ResetSection;
