import React, { useState } from 'react';
import ServiceGateway from '../../../../services/ServiceGateway';
import { useKwsState } from '../../../../contexts/KwsStateContext/KwsStateContext';
import Container from '../../../Common/Container';
import { Button } from '../../../Common/Button';
import Checkbox from '../../../Common/Checkbox';
import DateInput from '../../../Common/DateInput';
import { ReactComponent as HelpIcon } from '../../../../assets/help.svg';
import { ReactComponent as AlertIcon } from '../../../../assets/alert.svg';
import { ReactComponent as CarretIcon } from '../../../../assets/carret.svg';
import { formatAmount, removeNegativeSign, formatDate } from '../../../../utils/helper';
import { ItemState } from '../../../../types/Overview/Common';
import { NotificationModel } from '../../../../types/Overview/NotificationModel';
import { StandingOrderModel } from '../../../../types/Overview/StandingOrderModel';

interface NotificationProps {
  notification: any;
  errors: any;
  update: (v: any) => void;
}

interface InvalidDatesCardProps {
  notifications: NotificationModel[];
  standingOrders: StandingOrderModel[];
  date: any;
}

const Notification = ({ notification, errors, update }: NotificationProps): any => {
  const [notificationV] = useState(notification);
  const [selected, setSelected] = useState(
    notificationV.state === ItemState.NOTIFY || notificationV.state === ItemState.TO_COMPLETE,
  );
  const [expand, setExpand] = useState(false);

  function onCheck(v: boolean) {
    setSelected(v);
    const notificationVTemp = { ...notificationV, selected: v };
    update(notificationVTemp);
  }

  function onChange(v: any) {
    const notificationVTemp = { ...notificationV };
    notificationVTemp.selected = selected;
    notificationVTemp.dates.switchingDate = v;
    update(notificationVTemp);
  }

  return (
    <div>
      <div className='transaction highlight'>
        <div className='row'>
          <div className='col-1'>
            <Checkbox onChange={(e) => onCheck(e)} checked={selected} inputId={notificationV.id} />
          </div>
          <div className='col-2'>
            <div className='name line-clamp-1'>
              {notificationV.normalizedCounterName
                ? notificationV.normalizedCounterName
                : notificationV.recipientName}
            </div>
            <div className='category'>{notificationV.categoryLocale}</div>
            <div className='action'>
              <div className='toggle' onClick={() => setExpand(true)}>
                <div>anderes Umzugsdatum wählen</div>
                <CarretIcon className={expand ? 'open' : ''} />
              </div>
            </div>
          </div>
          {notificationV.transactions?.length ? (
            <div className='col-3'>
              <div
                className={`amount ${
                  notificationV.transactions[0].amount > 0 ? 'positive' : 'negative'
                }`}
              >
                {`${notificationV.transactions[0].amount > 0 ? '+' : '-'}${removeNegativeSign(
                  formatAmount(notificationV.transactions[0].amount),
                )} €`}
              </div>
              <div className='frequency'>{notificationV.frequencyLocale}</div>
            </div>
          ) : null}
        </div>
      </div>
      {expand && (
        <div className='edit-date'>
          <DateInput
            value={notificationV.dates?.earliestSwitchingDate}
            onChange={(v) => onChange(v)}
          />
          {errors?.notifications &&
            errors.notifications.findIndex((x: any) => x.id === notificationV.id) !== -1 && (
              <div className='negative'>
                {
                  errors.notifications[
                    errors.notifications.findIndex((x: any) => x.id === notificationV.id)
                  ]?.message
                }
              </div>
            )}
        </div>
      )}
    </div>
  );
};

const InvalidDatesCard = ({ notifications, standingOrders, date }: InvalidDatesCardProps) => {
  const notificationsTmp: any = notifications.map((n: NotificationModel) => ({
    id: n.id,
    select: n.state === ItemState.NOTIFY || n.state === ItemState.TO_COMPLETE,
    switchingDate: n.dates.earliestSwitchingDate,
  }));

  const ordersTmp: any = standingOrders.map((o: StandingOrderModel) => ({
    id: o.id,
    select: o.state === ItemState.NOTIFY || o.state === ItemState.TO_COMPLETE,
    switchingDate: o.dates.earliestSwitchingDate,
  }));

  const { kwsState, refreshKwsState } = useKwsState();
  const [notificationsV, setNotifications] = useState(notificationsTmp);
  const [standingOrdersV, setOrders] = useState(ordersTmp);
  const [validationError, setValidationError] = useState<Record<string, any>>({});

  const errorCheck = !(
    notificationsV.filter((x: any) => x.switchingDate === '').length === 0 &&
    standingOrdersV.filter((x: any) => x.switchingDate === '').length === 0
  );

  const submit = async (ignore: boolean) => {
    try {
      const data = {
        ignore,
        notifications: notificationsV,
        standingOrders: standingOrdersV,
      };
      const result = await ServiceGateway.invalidSwitchingDates(kwsState!.id, data);
      const isSuccess = result.status >= 200 && result.status < 300;
      if (isSuccess) {
        refreshKwsState();
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        if (error.response?.data) {
          setValidationError({
            notifications: [
              ...error.response.data.notifications,
              ...error.response.data.standingOrders,
            ],
          });
        }
      }
    }
  };

  const updateNotification = (notification: any) => {
    const tmp = [...notificationsV];
    const notificationToUpdate = tmp.find((x) => x.id === notification.id);
    notificationToUpdate.switchingDate = notification.dates.switchingDate;
    notificationToUpdate.select = notification.selected;
    setNotifications(tmp);
  };

  const updateOrder = (order: any) => {
    const tmp = [...standingOrdersV];
    const orderToUpdate = tmp.find((x) => x.id === order.id);
    orderToUpdate.switchingDate = order.dates.switchingDate;
    orderToUpdate.select = order.selected;
    setOrders(tmp);
  };

  const earliestDate = [
    notifications?.[0]?.dates?.earliestSwitchingDate,
    standingOrders?.[0]?.dates?.earliestSwitchingDate,
  ].sort((a: any, b: any) => Date.parse(a) - Date.parse(b))?.[0];

  return (
    <Container>
      <div className='invalid-dates-card'>
        <div className='header'>
          <div className='flex'>
            <AlertIcon className='alert-icon' />
            <h2>Neue Umzugsdaten bestätigen</h2>
          </div>
          <HelpIcon className='help icon' />
        </div>
        <div className='text'>
          {`Leider können die geplanten Umzugsdaten von ${
            (notifications ? notifications.length : 0) +
            (standingOrders ? standingOrders.length : 0)
          } Partnern nicht mehr eingehalten werden. Wir empfehlen diese schnellstmöglich zum ${
            date ? formatDate(earliestDate) : ''
          } umzustellen.`}
        </div>

        {notifications && notifications.length ? (
          <div className='section'>
            <div className='header'>Neue Kontoverbindungen mitteilen</div>
            <div>
              <div className='notifications'>
                {notifications.map((t) => (
                  <Notification
                    key={t.id}
                    notification={t}
                    errors={validationError}
                    update={updateNotification}
                  />
                ))}
              </div>
            </div>
          </div>
        ) : null}

        {standingOrders && standingOrders.length ? (
          <div className='section'>
            <div className='header'>Daueraufträge einrichten</div>
            <div>
              <div className='strandingOrders'>
                {standingOrders.map((t) => (
                  <Notification
                    key={t.id}
                    notification={t}
                    errors={validationError}
                    update={updateOrder}
                  />
                ))}
              </div>
            </div>
          </div>
        ) : null}

        {/* CTA */}
        <div className='form-group'>
          <div className='buttons'>
            <Button
              onClick={() => submit(false)}
              label='Kontoumzug aktualisieren'
              isDisabled={errorCheck}
            />
            {errorCheck && (
              <div className='form-error'>Bitte geben Sie die fehlenden Daten ein</div>
            )}
            <div className='c24-link' onClick={() => submit(true)}>
              Partner nicht benachrichtigen
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default InvalidDatesCard;
