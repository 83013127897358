import React from 'react';
import './index.scss';

import Container from '../../../../Common/Container';
import ReviewTransaction from './components/ReviewTransaction';
import AccountClosing from './components/AccountClosing';
import { isApprovedState, sort } from '../../../../../utils/helper';
import Tooltip from '../../../../Common/Tooltip';
import { useOverview } from '../../../../../contexts/OverviewContext/OverviewContext';
import InnerBorderContainer from '../../../../Common/InnerBorderContainer';
import { TooltipStateEnum } from '../../../../../contexts/TooltipStateContext/Tooltip.types';
import { NotificationModel } from '../../../../../types/Overview/NotificationModel';
import { StandingOrderModel } from '../../../../../types/Overview/StandingOrderModel';
import { useFrontendState } from '../../../../../contexts/FrontendRuleContext/FrontendRuleContext';
import {
  FrontEndSection,
  HintSection,
  HintTypes,
} from '../../../../../contexts/FrontendRuleContext/FrontendRule.types';

const ReviewSection = () => {
  const { overviewData } = useOverview();
  const { notifications, standingOrders, closingData } = overviewData!;
  const { decideHintToShow, decideSectionToShow } = useFrontendState();

  const approvedNotifications = notifications?.filter((notif) => isApprovedState(notif.state));
  const approvedStandingOrders = standingOrders?.filter((so) => isApprovedState(so.state));

  const tempFilteredApprovedNotifications = () => {
    const ssoIbanChangeNotPossibleForC24 = decideHintToShow({
      type: HintTypes.SECTION,
      hint: HintSection.SSO_IBAN_CHANGE_NOT_POSSIBLE_FOR_C24,
    });
    if (ssoIbanChangeNotPossibleForC24) {
      return approvedNotifications.filter(
        (notification: NotificationModel) => notification.normalizedCounterName !== 'Check24 GmbH',
      );
    }
    return approvedNotifications;
  };

  const approvedSortedNotifications: NotificationModel[] =
    approvedNotifications && sort(tempFilteredApprovedNotifications(), 'amount');

  const approvedSortedSO = approvedStandingOrders && sort(approvedStandingOrders, 'amount');

  const showNotifications = approvedSortedNotifications && approvedSortedNotifications.length > 0;
  const showOrders =
    decideSectionToShow(FrontEndSection.STANDING_ORDERS) &&
    approvedSortedSO &&
    approvedSortedSO.length > 0;

  return (
    <Container>
      <div className='review-section'>
        <div>
          <div className='header'>
            <h2>Das haben wir für Sie umgezogen</h2>
            <Tooltip tooltipSectionName={TooltipStateEnum.REVIEW_SECTION}>
              <p>
                Wir haben hier für Sie nochmals alle durchgeführten Umzugsschritte zusammengefasst.
              </p>
            </Tooltip>
          </div>
        </div>
        {showNotifications ? (
          <div className='section'>
            <div className='header'>Informierte Zahlungspartner</div>
            <InnerBorderContainer>
              {approvedSortedNotifications.map((notification: NotificationModel) => (
                <ReviewTransaction key={notification.id} reviewTransaction={notification} />
              ))}
            </InnerBorderContainer>
          </div>
        ) : null}
        {showOrders ? (
          <div className='section'>
            <div className='header'>Umgezogene Daueraufträge</div>
            <InnerBorderContainer>
              {approvedSortedSO.map((order: StandingOrderModel) => (
                <ReviewTransaction key={order.id} reviewTransaction={order} />
              ))}
            </InnerBorderContainer>
          </div>
        ) : null}
        {isApprovedState(closingData.state) && (
          <div className='section'>
            <div className='header'>Geschlossenes Altkonto</div>
            <InnerBorderContainer>
              <AccountClosing
                accountClosingDate={closingData.accountClosingDate}
                newBankName={closingData.newBank?.name}
                oldBankIban={closingData.oldBank?.iban}
              />
            </InnerBorderContainer>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ReviewSection;
