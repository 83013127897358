import React from 'react';
import './index.scss';
import { ReactComponent as ArrowIcon } from '../../../assets/arrow.svg';
import BankCard from '../BankCard';
import { OverviewBankModel } from '../../../types/Overview/ClosingDataModel';
import { DeviceOutputEnum } from '../../../utils/deviceOutput';
import { isDesktopOrTablet } from '../../../utils/helper';

interface TransferBankCardProps {
  oldBank: OverviewBankModel;
  newBank: OverviewBankModel;
}
const TransferBankCard = ({ oldBank, newBank }: TransferBankCardProps) => {
  const isTop = !isDesktopOrTablet();
  return (
    <div className={`transfer-card ${isTop ? '' : 'desktop'}`} data-testid='transfer-card-page'>
      <BankCard isTop={isTop} name={oldBank.name} iban={oldBank.iban} logoName={oldBank.logoName} />
      <div className='arrow'>
        <ArrowIcon className='arrow-icon' />
      </div>
      <BankCard isTop={isTop} name={newBank.name} iban={newBank.iban} logoName={newBank.logoName} />
    </div>
  );
};

export default TransferBankCard;
