import React, { useEffect, useState } from 'react';
import ServiceGateway from '../../../services/ServiceGateway';

import { useKwsState } from '../../../contexts/KwsStateContext/KwsStateContext';
import { sort } from '../../../utils/helper';
import InvalidDatesCard from './components/InvalidDatesCard';
import ContactCard from '../../Common/ContactCard';
import './index.scss';

interface InvalidSwitchingDatesData {
  globalDate: any;
  notifications: any[];
  standingOrders: any[];
}

const initialState: InvalidSwitchingDatesData = {
  globalDate: null,
  notifications: [],
  standingOrders: [],
};

const InvalidSwitchingDates = () => {
  const { kwsState } = useKwsState();
  const [state, setState] = useState(initialState);

  const invalidNotfications = (notifications: any) => {
    const result = notifications.filter((n: any) => n.dates.invalidSwitchingDate);
    result.sort((a: any, b: any) => a.category.localeCompare(b.category), 'de');
    return result;
  };

  const invalidStandingOrders = (standingOrders: any) => {
    const result = standingOrders.filter((n: any) => n.dates.invalidSwitchingDate);
    result.sort((a: any, b: any) => a.category.localeCompare(b.category), 'de');
    return result;
  };

  useEffect(() => {
    ServiceGateway.getOverviewPage(kwsState!.id).then((response) => {
      const overview = response.data;
      const notifications = invalidNotfications([...overview.notifications]);
      const standingOrders = invalidStandingOrders([...overview.standingOrders]);

      setState({
        ...state,
        globalDate: overview.globalDate,
        notifications,
        standingOrders,
      });
    });
  }, []);

  return state.notifications?.length || state.standingOrders?.length ? (
    <div className='overview'>
      <div>
        <InvalidDatesCard
          notifications={sort(state.notifications, 'amount')}
          standingOrders={sort(state.standingOrders, 'amount')}
          date={state.globalDate}
        />
      </div>
      <ContactCard />
    </div>
  ) : null;
};

export default InvalidSwitchingDates;
