import React from 'react';
import './index.scss';
import Radio from '../Radio';
import { Gender } from '../../../types/Overview/Common';

export enum RadioButtonGroupLayoutDirection {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export type RadioProps = {
  value: any;
  name: string;
  disabled?: boolean;
  className?: string;
  label: string;
  onChange: (val: any) => void;
  checked: boolean;
};

type RadioButtonGroupProps = {
  buttons: RadioProps[];
  hasError?: boolean;
  groupLabel?: string;
  layoutDirection?: RadioButtonGroupLayoutDirection;
  isWithoutBorder?: boolean;
};

const RadioButtonGroup = ({
  buttons,
  hasError,
  layoutDirection,
  groupLabel,
  isWithoutBorder,
}: RadioButtonGroupProps) => (
  <div
    className={`radio-button-group ${hasError ? 'error' : ''} ${
      isWithoutBorder ? 'no-border-layout' : ''
    } ${groupLabel ? 'top-padding' : ''}`}
    data-testid='radio-button-group'
  >
    {groupLabel ? <div className='group-label'>{groupLabel}</div> : null}
    <div
      className={`radio-buttons ${
        buttons.length > 6 || layoutDirection === RadioButtonGroupLayoutDirection.VERTICAL
          ? RadioButtonGroupLayoutDirection.VERTICAL
          : `${
              layoutDirection
                ? `horizontal grid-cols-${buttons.length}`
                : `grid-cols-${buttons.length}`
            }`
      }`}
      data-testid='radio-buttons'
    >
      {buttons.map((btn) => (
        <Radio
          value={btn.value}
          name={btn.name}
          label={btn.label}
          onChange={btn.onChange}
          checked={btn.checked}
          disabled={btn?.disabled}
          className={btn?.className}
          key={btn.label}
        />
      ))}
    </div>
  </div>
);

export default RadioButtonGroup;
