import React, { useEffect, useState } from 'react';
import ServiceGateway, { logoutSystem } from '../../../services/ServiceGateway';
import ContactCard from '../../Common/ContactCard';
import WelcomeSection from './sections/WelcomeBanner/index';
import SwitchingDateSection from './sections/SwitchingDate/index';
import NotificationsSection from '../CommonSections/Notifications';
import StandingOrdersSection from '../CommonSections/StandingOrders';
import AccountClosingSection from '../CommonSections/AccountClosing/index';
import GuaranteeInfoSection from '../CommonSections/GuaranteeInfo/index';
import { useKwsState } from '../../../contexts/KwsStateContext/KwsStateContext';
import { useOverview } from '../../../contexts/OverviewContext/OverviewContext';
import { getLastScrollPosition, scrollToPosition } from '../../../utils/helper';
import { WorkflowEnum } from '../../../contexts/KwsStateContext/KwsState.types';
import './index.scss';
import SpinnerFullPage from '../../Common/SpinnerFullPage';
import ResetSection from './sections/ResetSection';
import { ItemState } from '../../../types/Overview/Common';
import StickyButtons from '../../Common/StickyButtons';
import { NotificationModel } from '../../../types/Overview/NotificationModel';
import { StandingOrderModel } from '../../../types/Overview/StandingOrderModel';
import { FrontEndSection } from '../../../contexts/FrontendRuleContext/FrontendRule.types';
import { useFrontendState } from '../../../contexts/FrontendRuleContext/FrontendRuleContext';

const Overview = () => {
  const [loading, setLoading] = useState(false);
  const { kwsState, refreshKwsState } = useKwsState();
  const { overviewData, fillOverviewData } = useOverview();
  const { decideSectionToShow } = useFrontendState();

  const submit = () => {
    setLoading(true);
    ServiceGateway.completeEditing(kwsState!.id).then(() => {
      refreshKwsState();
      setLoading(false);
    });
  };

  useEffect(() => {
    const scrollPosition = getLastScrollPosition();
    scrollToPosition(scrollPosition);
  }, [overviewData]);

  const getOverviewData = async () => {
    const response = await ServiceGateway.getOverviewPage(kwsState!.id);
    fillOverviewData(response.data);
  };

  useEffect(() => {
    getOverviewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!overviewData) {
    return <SpinnerFullPage />;
  }

  const isCTAButtonEnabled =
    overviewData.notifications?.some(
      (notif: NotificationModel) => notif.state === ItemState.NOTIFY,
    ) ||
    overviewData.standingOrders?.some(
      (notif: StandingOrderModel) => notif.state === ItemState.NOTIFY,
    ) ||
    overviewData.closingData.state === ItemState.NOTIFY;

  const isNotificationsEmpty = overviewData.notifications?.length === 0;

  return (
    <div className='overview' data-testid='overview-page'>
      {kwsState!.workflow === WorkflowEnum.MANUAL_ATTACH_IBAN && <ResetSection />}

      {decideSectionToShow(FrontEndSection.WELCOME) && <WelcomeSection />}

      {decideSectionToShow(FrontEndSection.SWITCHING_DATE) && !isNotificationsEmpty && (
        <SwitchingDateSection />
      )}

      {decideSectionToShow(FrontEndSection.NOTIFICATIONS) && <NotificationsSection />}

      {decideSectionToShow(FrontEndSection.STANDING_ORDERS) &&
        !(kwsState!.workflow === WorkflowEnum.MANUAL_ATTACH_IBAN) && <StandingOrdersSection />}

      {decideSectionToShow(FrontEndSection.CLOSE_ACCOUNT) && <AccountClosingSection />}

      {decideSectionToShow(FrontEndSection.CTA_BUTTON) && (
        <StickyButtons
          isDisabled={!isCTAButtonEnabled}
          isLoading={loading}
          proceedButton={{ label: 'weiter zur Unterschrift', onClick: submit }}
          cancelButton={{ label: 'speichern und abmelden', onCancel: logoutSystem }}
        />
      )}

      {decideSectionToShow(FrontEndSection.GUARANTEE_INFO) && <GuaranteeInfoSection />}

      {decideSectionToShow(FrontEndSection.CONTACT_CARD) && <ContactCard />}
    </div>
  );
};

export default Overview;
