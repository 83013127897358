import React, { useState } from 'react';
import './index.scss';
import { ReactComponent as AlertIcon } from '../../../assets/alert.svg';
import { ReactComponent as CloseIcon } from '../../../assets/close.svg';
import Button from '../Button';

interface InfoBoxProps {
  title?: string;
  type?: 'alert' | 'info';
  button?: {
    label: string;
    onClick: () => void;
  };
  closeButton?: {
    sessionTitle: string;
  };
  children?: React.ReactNode;
}

const InfoBox = ({ title, button, type, closeButton, children }: InfoBoxProps) => {
  const [isAlertHiddenInSession, setIsAlertHiddenInSession] = useState(
    sessionStorage.getItem(closeButton?.sessionTitle || 'alert') === 'true',
  );

  const closeAlertForSession = () => {
    sessionStorage.setItem(closeButton?.sessionTitle || 'alert', 'true');
    setIsAlertHiddenInSession(true);
  };

  if (isAlertHiddenInSession) return null;

  return (
    <div className={`info-box ${type}`} data-testid='info-box'>
      <div className='body'>
        {closeButton && (
          <div className='close-button' onClick={closeAlertForSession} data-testid='close-button'>
            <CloseIcon />
          </div>
        )}
        {title && (
          <div className='header'>
            <AlertIcon className='alert-icon' />
            <p>{title}</p>
          </div>
        )}
        {children ? <div className='content'>{children}</div> : null}
      </div>

      {button && <Button classNames='alternative' onClick={button.onClick} label={button.label} />}
    </div>
  );
};

export default InfoBox;
