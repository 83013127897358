import React, { useEffect, useRef, useState } from 'react';
import isEqual from 'lodash.isequal';
import { ValidationResult, Validator } from '../../../utils/validators';
import './index.scss';
import { ReactComponent as CarretIcon } from '../../../assets/carret.svg';

interface Options {
  placeholder: string;
  value: string;
}

interface SelectInputProps {
  placeholder: string;
  validator?: Validator;
  validation?: ValidationResult;
  icon?: any;
  className?: string;
  value: string;
  options?: Options[];
  onChange?: (v: string) => void;
  onError?: (validation: ValidationResult) => void;
  emptyOptionExist?: boolean;
  emptyOptionEnabled?: boolean;
}

interface SelectInputState {
  focus: boolean;
  value: string;
  validation: ValidationResult;
}

const initialState: SelectInputState = {
  focus: false,
  value: '',
  validation: {
    valid: true,
    message: '',
  },
};

const SelectInput = ({
  validator,
  onChange,
  onError,
  value,
  validation,
  className,
  emptyOptionEnabled,
  emptyOptionExist,
  options,
  placeholder,
  icon,
}: SelectInputProps) => {
  const [state, setState] = useState(initialState);
  const [showErrors, setShowErrors] = useState(false);
  const prevPropsValidation = useRef<ValidationResult>();

  const handleChange = (e: any) => {
    const v = e.target.value || '';
    const validationV = validator?.validate(v);
    setState({
      ...state,
      value: v,
      validation: validationV ?? initialState.validation,
    });
    if (onChange) {
      onChange(v);
    }
    if (onError && validationV) {
      if (!validationV.valid) {
        onError(validationV);
      } else {
        onError({ valid: true });
      }
    }
  };

  useEffect(() => {
    if (state.validation?.valid || state.focus) {
      setShowErrors(false);
    } else {
      setShowErrors(true);
    }
  }, [state.focus, state.validation]);

  useEffect(() => {
    if (value) {
      setState({
        ...state,
        value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (validation && !isEqual(prevPropsValidation.current, validation)) {
      prevPropsValidation.current = validation;
      setState({
        ...state,
        validation,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation]);

  useEffect(() => {
    if (state.value) {
      const validationV = validator?.validate(state.value);
      if (validationV && !validationV.valid) {
        setState({ ...state, validation: validationV });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value]);

  return (
    <div className={`select-input ${showErrors ? 'error ' : ''} ${className ?? ''}`}>
      <div className='inner'>
        <select
          className={state.value ? 'has-value' : ''}
          value={state.value}
          onChange={handleChange}
          onFocus={() => setState({ ...state, focus: true })}
          onBlur={() => setState({ ...state, focus: false })}
          // onMouseOut={() => setState({ ...state, focus: false })}
        >
          {emptyOptionExist && (
            <option value='' disabled={!emptyOptionEnabled} key='selector'>
              Bitte wählen
            </option>
          )}
          {options?.map((o) => (
            <option key={o.value} value={o.value}>
              {o.placeholder}
            </option>
          ))}
        </select>
        <div className='floating-label'>{placeholder}</div>
        <div className='append-inner'>
          <CarretIcon className={`dropdown-icon${state.focus ? ' open' : ''}`} />
        </div>
      </div>
      {showErrors && <div className='error'> {state.validation?.message} </div>}
    </div>
  );
};

export default SelectInput;
