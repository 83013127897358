import {
  CustomerDataModel,
  Dates,
  Frequency,
  ItemState,
  Recipient,
  TransactionModel,
  ItemAction,
  ItemType,
} from './Common';
import { GlobalDate } from './GlobalDate';

export enum NotificationType {
  RELEVANT = 'RELEVANT',
  NOT_RELEVANT = 'NOT_RELEVANT',
  UNKNOWN = 'UNKNOWN',
}

export interface NotificationDetailsModel {
  id: string;
  itemType: ItemType;
  type: NotificationType;
  state: ItemState;
  action: ItemAction;
  modified: boolean;
  added: boolean;
  inactive: boolean;
  private: boolean;
  referenceNumber: string;
  category: string;
  categoryLocale: string;
  normalizedCounterName: string;
  frequency: Frequency;
  frequencyLocale: string;
  recipient?: Recipient;
  customerData: CustomerDataModel;
  dates: Dates;
  transactions?: TransactionModel[];
}

export type NotificationModel = Omit<
  NotificationDetailsModel,
  'referenceNumber' | 'recipient' | 'transactions'
> & { amount: number | null };

export interface EditNotificationsModel {
  globalDate: GlobalDate;
  notifications: NotificationModel[];
}
