import React, { useEffect, useRef, useState } from 'react';
import isEqual from 'lodash.isequal';
import { ValidationResult, Validator } from '../../../../utils/validators';
import './index.scss';
import { scrollToTopForMobileAndApp } from '../../../../utils/helper';

interface PostfachInputProps {
  disabled?: boolean;
  disabledJustInput?: boolean;
  placeholder: string;
  validator?: Validator;
  validation?: ValidationResult;
  icon?: any;
  className?: string;
  value: string | null;
  onChange?: (v: string, valid?: boolean) => void;
  onError?: (validation: ValidationResult) => void;
  onBlurChange?: (value: string | null) => void;
  onFocusChange?: (focus: boolean) => void;
  disableErrorText?: boolean;
  inputRef?: any;
  inputRegex?: RegExp;
  inputMode?: any;
  optional?: boolean;
  optionalText?: string;
}

interface PostfachInputState {
  focus: boolean;
  value: string | null;
  validation: ValidationResult;
}

const initialState: PostfachInputState = {
  focus: false,
  value: '',
  validation: {
    valid: true,
    message: '',
  },
};

const PostFachInput = (props: PostfachInputProps) => {
  const {
    disabled,
    disableErrorText,
    disabledJustInput,
    placeholder,
    validation,
    validator,
    value,
    icon,
    className,
    onChange,
    onBlurChange,
    onError,
    onFocusChange,
    optional,
    optionalText,
    inputMode,
    inputRef,
    inputRegex,
  } = props;
  const [state, setState] = useState(initialState);
  const [showErrors, setShowErrors] = useState(false);
  const prevPropsValidation = useRef<ValidationResult>();

  const addPostFachPrefix = (val: string | null) => {
    const cleanNonNumeric = val ? val.replace(/[^0-9]/g, '') : val;
    return cleanNonNumeric ? `Postfach ${cleanNonNumeric}` : null;
  };

  const handleChange = (e: any) => {
    const valueV = e.currentTarget.value || '';
    const regexValue = inputRegex ? valueV.replace(inputRegex, '') : valueV;
    const validationV = validator?.validate(regexValue);
    setState({
      ...state,
      value: regexValue ? addPostFachPrefix(regexValue) : null,
      validation: validationV ?? initialState.validation,
    });
    if (onChange) {
      onChange(regexValue || null, validationV?.valid);
    }
    if (onError && validationV) {
      if (!validationV.valid) {
        onError(validationV);
      } else {
        onError({ valid: true });
      }
    }
  };

  useEffect(() => {
    if (state.validation?.valid || state.focus) {
      setShowErrors(false);
    } else {
      setShowErrors(true);
    }
  }, [state.focus, state.validation]);

  useEffect(() => {
    if (validation && !isEqual(prevPropsValidation.current, validation)) {
      prevPropsValidation.current = validation;
      setState({
        ...state,
        validation,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation]);

  useEffect(() => {
    if (typeof value !== 'undefined') {
      setState((oldState) => ({
        ...oldState,
        value: addPostFachPrefix(value),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div
      className={`text-input ${showErrors ? 'error ' : ''}${state.focus ? 'focus ' : ''}${
        disabled ? 'disabled ' : ''
      }${className ?? ''}`}
    >
      <div className='inner'>
        <input
          className={state.value ? 'has-value' : ''}
          type='text'
          inputMode={inputMode ?? 'text'}
          value={state.value ?? ''}
          onChange={handleChange}
          onFocus={(e) => {
            if (onFocusChange) {
              onFocusChange(true);
            }
            scrollToTopForMobileAndApp(window.scrollY, e.target.getBoundingClientRect().y);
            setState({ ...state, focus: true });
          }}
          onBlur={() => {
            setState({ ...state, focus: false });
            if (onFocusChange) {
              onFocusChange(false);
            }
            if (onBlurChange) {
              onBlurChange(state.value);
            }
          }}
          disabled={disabled}
          readOnly={disabledJustInput}
          ref={inputRef}
        />
        <div className='floating-label'>{`${placeholder}${
          optional && !state.value && !state.focus ? ` ${optionalText ?? '(optional)'}` : ''
        }`}</div>
        {icon && (
          <div className='append-inner'>
            <img className='help' src={icon} alt='' />
          </div>
        )}
      </div>
      {showErrors && !disableErrorText && (
        <div className='error'> {state.validation?.message} </div>
      )}
    </div>
  );
};

export default PostFachInput;
