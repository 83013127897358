import React, { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import Spinner from '../../Common/Spinner';
import ServiceGateway from '../../../services/ServiceGateway';
import './fintec-styles/fintec-index.styles.scss';
import './index.scss';
import { useKwsState } from '../../../contexts/KwsStateContext/KwsStateContext';
import useDebounce from '../../../hooks/useDebounce';
import Container from '../../Common/Container';
import ContactCard from '../../Common/ContactCard';
import { ReactComponent as CarretIcon } from '../../../assets/carret.svg';
import ProgressBar from '../../Common/ProgressBar';
import { ProcessStateEnum } from '../../../contexts/KwsStateContext/KwsState.types';
import InfoBox from '../../Common/InfoBox';
import Toggle from '../../Common/Toggle';
import Tooltip from '../../Common/Tooltip';
import { ValidationResult } from '../../../utils/validators';
import Button from '../../Common/Button';
import IbanInput from '../../Common/IbanInput';
import Modal from '../../Common/Modal';
import { isProd } from '../../../utils/environment';
import { TooltipStateEnum } from '../../../contexts/TooltipStateContext/Tooltip.types';
import { DacPageModel } from '../../../types/DacPage/DacPageModel';
import WelcomeSection from './sections/WelcomeSection';
import FunctionSection from './sections/FunctionsSection';
import { DeviceOutputEnum } from '../../../utils/deviceOutput';
import TextFieldAutoComplete from '../../Common/TextFieldAutoComplete';

declare const xs2a: any;

interface DacState {
  state: 'LOADING' | 'CONFIGURE' | 'START' | 'FINISHED' | 'ERROR';
  wizardKey: string | null;
  processingCompleted: boolean;
  processingTime?: number;
}

const initalState: DacState = {
  state: 'LOADING',
  wizardKey: null,
  processingCompleted: false,
};

interface PossibleBank {
  name?: string;
  code: string;
}

const Dac = () => {
  const { kwsState, refreshKwsState } = useKwsState();
  const [value, setValue] = useState(initalState);
  const [loading, setLoading] = useState(false);
  const [bankCodeSource, setBankCodeSource] = useState<string | undefined>();
  const [showTinkModal, setShowTinkModal] = useState('');
  const [bankSearchText, setBankSearchText] = useState<string>('');
  const [possibleBankList, setPossibleBankList] = useState<PossibleBank[]>([]);
  const [bankData, setBankData] = useState<PossibleBank>();
  const [bankErrorMessage, setBankErrorMessage] = useState();
  const debouncedBankSearchText = useDebounce<string>(bankSearchText, 1000);
  const showManualToggleRef = useRef(false);
  const [dacPageModel, setDacPageModel] = useState<DacPageModel>();

  const [expandManual, setExpandManual] = useState(false);
  const [manualIban, setManualIban] = useState('');
  const [manualIbanValidationError, setManualIbanValidationError] = useState<ValidationResult>({
    valid: true,
  });
  const manualKwsRef = useRef<HTMLDivElement>(null);
  const [bankSelectorDisplay, setBankSelectorDisplay] = useState('');

  const initTinkProcess = async () => {
    try {
      const wizardKey = await ServiceGateway.initDac(kwsState!.id);
      setValue({
        ...value,
        state: 'CONFIGURE',
        wizardKey: wizardKey.data,
      });
    } catch (error: any) {
      if (error?.response?.status === 400) {
        setValue({
          ...value,
          state: 'ERROR',
        });
      }
    }
  };

  const getDacPageData = async () => {
    const result = await ServiceGateway.getDacPage(kwsState!.id);
    setDacPageModel(result.data);
    setBankCodeSource(result.data.oldBank?.bankCode);
  };

  const getBankList = async (term: string): Promise<any[]> => {
    setLoading(true);
    setPossibleBankList([]);
    const result = await ServiceGateway.getPossibleBankList(term);

    const isSuccess = result.status >= 200 && result.status < 300;
    if (isSuccess) {
      setLoading(false);
      return result.data;
    }
    return [];
  };

  const selectBank = async (bank: any) => {
    if (bank) {
      setBankData(bank);
      setBankSearchText('');
      setPossibleBankList([]);
      setBankErrorMessage(undefined);

      setValue({
        ...value,
        state: 'LOADING',
      });
      try {
        await ServiceGateway.attachBankCodeSource(kwsState!.id, bank.code);
        setBankCodeSource(bank.code);
      } catch (bankSelectionError: any) {
        setBankCodeSource(undefined);
        const errorData = bankSelectionError?.response?.data?.errors?.[0]?.message;
        if (errorData) {
          setBankErrorMessage(errorData);
        }
      }
    }
  };

  const getPossibleBankList = async (term: string) => {
    const bankList = await getBankList(term);

    if (!isProd()) {
      bankList.unshift(
        ...[
          {
            city: 'Munich',
            code: '88888888',
            name: 'Test Bank 1',
            score: 0,
          },
          {
            city: 'Berlin',
            code: '88888889',
            name: 'Test Bank 2',
            score: 0,
          },
        ],
      );
    }
    setPossibleBankList(bankList);
  };

  const startManualKws = async () => {
    try {
      if (manualIban) {
        await ServiceGateway.attachIbanSource(kwsState!.id, manualIban);
        refreshKwsState();
      } else {
        setManualIbanValidationError({
          valid: false,
          message: 'Bitte geben Sie die IBAN Ihres bisherigen Kontos ein',
        });
      }
    } catch (err: any) {
      const errorData = err?.response?.data;
      if (errorData) {
        setManualIbanValidationError({ valid: false, message: errorData.firstErrorMessage });
      }
    }
  };

  useEffect(() => {
    if (debouncedBankSearchText?.length > 1) {
      getPossibleBankList(debouncedBankSearchText);
      if (!showManualToggleRef.current) {
        showManualToggleRef.current = true;
      }
    } else {
      setPossibleBankList([]);
    }
  }, [debouncedBankSearchText]);

  const checkState = async () => {
    const fetchDacResult = await ServiceGateway.getProcessState(kwsState!.id);
    if (fetchDacResult.data.processState === ProcessStateEnum.EDITING) {
      setValue((oldState) => ({
        ...oldState,
        processingCompleted: true,
      }));

      setTimeout(() => {
        refreshKwsState();
      }, 500);
    } else {
      setTimeout(() => {
        checkState();
      }, 1000);
    }
  };

  const xs2aFinish = async () => {
    const fetchDacResult = await ServiceGateway.fetchDac(kwsState!.id);

    setValue((oldState) => ({
      ...oldState,
      state: 'FINISHED',
      processingTime: fetchDacResult.data.processingTime,
    }));

    checkState();
  };

  function xs2aAbort() {
    ServiceGateway.resetDac(kwsState!.id).then(() => initTinkProcess());
  }

  function xs2aError(errorCode: any, messages: any, recoverable: any) {
    setTimeout(() => {
      const errorElement = document.getElementsByClassName('xs2a-error')?.[0] as HTMLElement;
      if (errorElement) {
        errorElement.setAttribute('tabIndex', '-1');
        errorElement.focus();
      }
    }, 100);

    ServiceGateway.dacError(kwsState!.id, value.wizardKey!, {
      type: 'LOGIN',
      errorCode,
      messages,
      recoverable,
    });
  }

  function updateLabel(input: any) {
    const parent = input.parentElement;
    if (input.value?.length > 0) {
      parent.classList.add('has-value');
    } else {
      parent.classList.remove('has-value');
    }
  }

  function toggleFocus(input: any, val: any) {
    const parent = input.parentElement;
    if (val) {
      parent.classList.add('focused');
    } else {
      parent.classList.remove('focused');
    }
  }

  const configureXs2a = (initCallback: () => void) => {
    // xs2a.useBaseStyles();
    xs2a.configure({
      'focus-onload': true,
      'validate-privacy-policy': false,
      'open-privacy-policy-in-tab': true,
      'validate-login-credentials': false,
      'reuse-tab-on-redirect': true,
    });

    xs2a.finish(xs2aFinish);
    xs2a.abort(xs2aAbort);
    xs2a.error(xs2aError);

    xs2a.render(() => {
      const root = document.getElementById('XS2A-Form');

      if (!root) {
        return;
      }

      const textDivs = root.getElementsByClassName('xs2a-text');
      if (textDivs) {
        for (let i = 0; i < textDivs.length; i++) {
          const input = textDivs[i].getElementsByTagName('input')?.[0];
          if (input) {
            input.onkeyup = () => {
              updateLabel(input);
            };
            input.onchange = () => {
              updateLabel(input);
            };
            input.onfocus = () => {
              toggleFocus(input, true);
            };
            input.onblur = () => {
              toggleFocus(input, false);
            };
            updateLabel(input);
          }
        }
      }

      const flickerDivs = root.getElementsByClassName('xs2a-flicker');
      if (flickerDivs) {
        for (let i = 0; i < flickerDivs.length; i++) {
          const input = flickerDivs[i].getElementsByTagName('input')?.[0];
          if (input) {
            input.onkeyup = () => {
              updateLabel(input);
            };
            input.onchange = () => {
              updateLabel(input);
            };
            input.onfocus = () => {
              toggleFocus(input, true);
            };
            input.onblur = () => {
              toggleFocus(input, false);
            };
            updateLabel(input);
          }
        }
      }

      const selectDivs = root.getElementsByClassName('xs2a-select');
      if (selectDivs) {
        for (let i = 0; i < selectDivs.length; i++) {
          const selectDiv = selectDivs[i];
          const svgDiv = document.createElement('div');
          svgDiv.className = 'svg-div';
          selectDiv.appendChild(svgDiv);
          const svg = <CarretIcon className='dropdown-icon' />;
          const rootSvg = createRoot(svgDiv);
          rootSvg.render(svg);
        }
      }

      const autoSubmitLine = root.getElementsByClassName('xs2a-autosubmit-line');
      if (autoSubmitLine?.length) {
        const spinner = <Spinner />;
        autoSubmitLine[0].innerHTML = '';
        createRoot(autoSubmitLine[0]).render(spinner);
      }

      /* prettier-ignore */
      const policyTags = root.querySelector(
        'label[for=\'XS2A-privacy_policy\']'
      )?.getElementsByTagName('a')
      if (policyTags) {
        for (let i = 0; i < policyTags.length; i++) {
          const policyTag = policyTags[i];

          const newTag = document.createElement('a');
          newTag.href = policyTag.href;
          newTag.target = '_blank';
          newTag.append(policyTag.childNodes[0]);
          newTag.addEventListener('click', (e: Event) => {
            if (window.wf_deviceoutput === DeviceOutputEnum.APP) {
              const url = new URL(policyTag.href);
              if (!url.host.includes('check24')) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                setShowTinkModal(policyTag.href);
              }
            }
          });
          policyTag.replaceWith(newTag);
        }
      }
    });

    initCallback();
  };

  useEffect(() => {
    if (value.state === 'CONFIGURE') {
      configureXs2a(() => {
        setValue((oldState) => ({
          ...oldState,
          state: 'START',
        }));
      });
    }
    let timer: ReturnType<typeof setTimeout>;
    if (value.state === 'START') {
      xs2a.init();
      document.getElementById('dac')?.scrollIntoView();
      timer = setTimeout(() => {
        document.getElementById('XS2A-USER_NAME')?.focus();
      }, 500);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [value.state]);

  useEffect(() => {
    if (bankCodeSource) {
      setBankData((prev) => ({ ...prev, code: bankCodeSource }));

      initTinkProcess();
    }
  }, [bankCodeSource]);

  useEffect(() => {
    getDacPageData();

    const script = document.createElement('script');
    script.setAttribute('id', 'xs2aScript');

    script.src = 'https://api.xs2a.com/xs2a.js';
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (bankData) {
      if (bankData.name && bankData.code) {
        setBankSelectorDisplay(`${bankData.name} (BLZ ${bankData.code})`);
      } else {
        setBankSelectorDisplay(bankData.code);
      }
    } else {
      setBankSelectorDisplay('');
    }
  }, [bankData]);

  useEffect(() => {
    if (expandManual) {
      const elementTop = manualKwsRef?.current?.getBoundingClientRect().top;
      if (elementTop) {
        const offsetPosition = elementTop && elementTop + window.scrollY - 24;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [expandManual]);

  const isTestBank = dacPageModel?.oldBank?.bic === 'bic_test';

  const renderDisplayValue = (data: PossibleBank) => {
    const { name, code } = data;
    return <span>{`${name} (BLZ ${code})`}</span>;
  };

  return (
    <div className='dac-page'>
      {dacPageModel && (
        <WelcomeSection customerData={dacPageModel.customerData} newBank={dacPageModel.newBank} />
      )}

      <FunctionSection />

      <Container>
        <div id='bank-search' className='bank-search' data-testid='bank-search'>
          <div className='title'>Bei welcher Bank haben Sie Ihr bisheriges Girokonto?</div>

          <TextFieldAutoComplete
            searchText={bankSearchText}
            setSearchText={setBankSearchText}
            displayedValue={bankSelectorDisplay}
            errorMessage={bankErrorMessage}
            possibleInputList={possibleBankList}
            selectPossibleInput={selectBank}
            renderDisplayValue={renderDisplayValue}
            placeholder='Bankname oder BLZ'
            loading={loading}
          />

          {showManualToggleRef.current && (
            <Toggle
              text='bisherige Bank nicht verfügbar?'
              onClick={() => setExpandManual(!expandManual)}
              expandProp={expandManual}
            />
          )}
        </div>
      </Container>

      {(dacPageModel?.oldBank.iban && !isTestBank) || bankCodeSource ? (
        <Container>
          <div className='dac' data-testid='dac'>
            <div className='header'>
              <div className='header-block'>
                <h2>Verbindung mit Ihrem Bankkonto</h2>
                <Tooltip tooltipSectionName={TooltipStateEnum.DAC_HEADER}>
                  <p>
                    Die Anmeldung mit den Online-Banking Zugangsdaten Ihres bisherigen Kontos ist
                    TÜV-zertifiziert und notwendig, um anhand Ihrer Kontoumsätze relevante
                    Zahlungspartner und Daueraufträge für Ihren Kontoumzug zu ermitteln. Nachdem Sie
                    die Auswahl überprüft und bei Bedarf angepasst haben, kann Ihr Kontoumzug
                    automatisch ausgeführt werden. Außerdem können Sie die Schließung Ihres alten
                    Kontos beauftragen. Sie behalten über jeden Umzugsschritt die volle Kontrolle.
                  </p>
                  <br />
                  <p>
                    Ihre Daten werden ausschließlich in verschlüsselter Form an Ihre Bank
                    übermittelt. Ihre Log-In Daten werden nicht gespeichert. Sensible Daten wie
                    Benutzername oder Passwort sind für CHECK24 zu keinem Zeitpunkt einsehbar.
                  </p>
                </Tooltip>
              </div>
              {value.state === 'ERROR' && (
                <InfoBox
                  title='Bank Login derzeit nicht möglich'
                  button={{ label: 'neu laden', onClick: initTinkProcess }}
                  type='alert'
                >
                  <p>
                    Aufgrund von Wartungsarbeiten kann der Zugang zu Ihrem Bankkonto derzeit nicht
                    hergestellt werden. Bitte versuchen Sie es später erneut, um den Kontoumzug
                    fortzusetzen. Für Rückfragen stehen wir Ihnen gerne unter 089 - 24 24 11 09 zur
                    Verfügung.
                  </p>
                </InfoBox>
              )}
            </div>
            {value.state === 'LOADING' && <Spinner />}
            {value.state !== 'LOADING' && value.state !== 'CONFIGURE' && (
              <div className='tink-widget'>
                <div className='fintec-form' id='XS2A-Form' data-xs2a={value.wizardKey} />
              </div>
            )}
            <Toggle
              text='Kontoumzug ohne Online-Banking'
              onClick={() => setExpandManual(!expandManual)}
              expandProp={expandManual}
            />
          </div>
        </Container>
      ) : null}

      {expandManual && (
        <Container>
          <div className='manual-kws' data-testid='manual-kws' ref={manualKwsRef}>
            <div className='manual-kws-body'>
              <div className='header'>
                <div className='header-block'>
                  <h2>Manueller Kontoumzug</h2>
                  <Tooltip tooltipSectionName={TooltipStateEnum.MANUAL_KWS}>
                    <p>
                      Ohne Anmeldung mit den Online-Banking Zugangsdaten Ihres bisherigen Kontos
                      können wir leider keine relevanten Zahlungspartner und Daueraufträge für Ihren
                      Kontoumzug ermitteln. Sie können Zahlungspartner aber manuell hinzufügen, die
                      wir dann automatisch über Ihre neue Bankverbindung informieren. Außerdem
                      können Sie die Schließung Ihres alten Kontos beauftragen und wir erledigen den
                      Rest.
                    </p>
                  </Tooltip>
                </div>
              </div>
              <div className='description-bubble'>
                <p>
                  Bitte geben Sie die IBAN Ihres bisherigen Kontos ein, das Sie umziehen möchten.
                </p>
              </div>
            </div>

            <IbanInput
              placeholder='IBAN eingeben'
              value={manualIban}
              onChange={(v) => setManualIban(v)}
              validation={manualIbanValidationError}
            />

            <p className='manual-transfer-legal'>
              Mit Klick auf &quot;weiter ohne Online-Banking&quot; gelten die{' '}
              <a
                href='https://frontend.kws.check24.de/frontend/download/TERMS_AND_CONDITIONS'
                target='_blank'
                rel='noreferrer'
              >
                AGB
              </a>{' '}
              und{' '}
              <a
                href='https://frontend.kws.check24.de/frontend/download/DATA_PROTECTION'
                target='_blank'
                rel='noreferrer'
              >
                Datenschutzhinweise
              </a>{' '}
              der CHECK24 Vergleichsportal Karten & Konten GmbH, welche die von mir bereitgestellten
              Bankverbindungsdaten und Finanzinformationen verarbeitet und mir das Ergebnis
              bereitstellt.
            </p>

            <Button
              classNames='alternative'
              onClick={startManualKws}
              label='weiter ohne Online-Banking'
            />
          </div>
        </Container>
      )}

      {value.state === 'FINISHED' && (
        <ProgressBar
          expectedWaitingTimeInSeconds={value.processingTime}
          done={value.processingCompleted}
        />
      )}

      <ContactCard />

      {showTinkModal && (
        <Modal appearance='full' onClose={() => setShowTinkModal('')}>
          <div className='tink-conditions'>
            <iframe title='tink-conditions' src={showTinkModal} frameBorder='0' />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Dac;
