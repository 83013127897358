import React, { useCallback, useMemo, useState } from 'react';
import ServiceGateway from '../../services/ServiceGateway';
import { isApprovedState, setLastScrollPosition, sort } from '../../utils/helper';
import { useKwsState } from '../KwsStateContext/KwsStateContext';
import { OverviewContext } from './OverviewContext';
import { GlobalDate } from '../../types/Overview/GlobalDate';
import { Overview } from '../../types/Overview/Overview';
import { INotificationCategoriesModel, IOverviewProvider } from './Overview.types';
import { ClosingDataModel } from '../../types/Overview/ClosingDataModel';
import { NotificationModel } from '../../types/Overview/NotificationModel';
import { StandingOrderModel } from '../../types/Overview/StandingOrderModel';
import { useFrontendState } from '../FrontendRuleContext/FrontendRuleContext';
import { ThanksPageModel } from '../../types/ThanksPage/ThanksPageModel';

const OverviewProvider: React.FC<Partial<IOverviewProvider>> = ({
  overviewData: overviewDataProps,
  updateNotification: updateNotificationMock,
  updateStandingOrder: updateStandingOrderMock,
  updateClosingData: updateClosingDataMock,
  children,
}) => {
  const { kwsState } = useKwsState();
  const { setFrontendRule } = useFrontendState();
  const [overviewData, setOverviewData] = useState<Overview | undefined>(overviewDataProps);
  const [notificationCategories, setNotificationCategories] =
    useState<INotificationCategoriesModel[]>();

  const categorizeNotifications = (
    notifications: NotificationModel[],
  ): INotificationCategoriesModel[] => {
    const categories: INotificationCategoriesModel[] = [];

    notifications
      .filter((notification) => !isApprovedState(notification.state))
      .forEach((notification) => {
        const category = categories.find(
          (x: INotificationCategoriesModel) => x.category === notification.categoryLocale,
        );
        if (category) {
          category.notifications.push(notification);
        } else {
          categories.push({
            category: notification.categoryLocale,
            notifications: [notification],
          });
        }
      });

    categories.sort((a, b) => a.category.localeCompare(b.category, 'de'));

    categories.forEach((category) => {
      category.notifications.sort((a, b) =>
        a.normalizedCounterName.localeCompare(b.normalizedCounterName, 'de'),
      );
    });

    return categories;
  };

  const sortAndCategorizeNotificationsAndSOrders = useCallback(
    (notifs: NotificationModel[], sOrders: StandingOrderModel[]) => {
      const notifications = sort(notifs, sessionStorage.getItem('c24-sort-1') ?? 'amount');

      const standingOrders = sOrders.sort((a, b) => {
        if (b.amount !== a.amount) {
          return b.amount - a.amount;
        }
        if (b.recipientName && a.recipientName) {
          return b.recipientName.localeCompare(a.recipientName, 'de');
        }
        return 0;
      });

      const categories = categorizeNotifications(notifications);

      return { notifications, standingOrders, categories };
    },
    [],
  );

  const fillOverviewData = useCallback(
    (data: Overview | ThanksPageModel) => {
      const { notifications, standingOrders, categories } =
        sortAndCategorizeNotificationsAndSOrders(data.notifications, data.standingOrders);

      setOverviewData({ ...data, notifications, standingOrders });
      setFrontendRule({ ...data.frontendRule });
      setNotificationCategories(categories);
    },
    [
      kwsState,
      setOverviewData,
      setNotificationCategories,
      sortAndCategorizeNotificationsAndSOrders,
      setFrontendRule,
    ],
  );

  const resetSwitchingDates = useCallback(
    (asap: boolean, globalDateObj: GlobalDate) => {
      setLastScrollPosition();

      const tempStateData = overviewData!;
      const tempNotifications = tempStateData.notifications;
      const tempSO = tempStateData.standingOrders;
      if (asap) {
        for (let i = 0; i < tempNotifications.length; i++) {
          tempNotifications[i].dates.switchingDate =
            tempNotifications[i]?.dates?.earliestSwitchingDate;
          tempNotifications[i].dates.inputSwitchingDate = null;
          tempNotifications[i].dates.asap = true;
        }
        for (let i = 0; i < tempSO.length; i++) {
          tempSO[i].dates.switchingDate = tempSO[i].dates.earliestSwitchingDate;
          tempSO[i].dates.inputSwitchingDate = null;
          tempSO[i].dates.asap = true;
        }
        tempStateData.globalDate = globalDateObj;
      } else {
        for (let i = 0; i < tempNotifications.length; i++) {
          tempNotifications[i].dates.switchingDate =
            tempNotifications[i].dates.earliestSwitchingDate;
          tempNotifications[i].dates.inputSwitchingDate = null;
          tempNotifications[i].dates.asap = false;
        }
        for (let i = 0; i < tempSO.length; i++) {
          tempSO[i].dates.switchingDate = tempSO[i].dates.earliestSwitchingDate;
          tempSO[i].dates.inputSwitchingDate = null;
          tempSO[i].dates.asap = false;
        }
        tempStateData.globalDate = globalDateObj;
      }

      setOverviewData({ ...tempStateData });
    },
    [overviewData, setOverviewData],
  );

  const updateCategories = useCallback(
    (notification: NotificationModel) => {
      const tempNotificationCategories = notificationCategories!;
      const category = tempNotificationCategories.find(
        (x: INotificationCategoriesModel) => x.category === notification.categoryLocale,
      );
      if (!category) {
        return;
      }
      const notificationIndex = category.notifications.findIndex(
        (x: NotificationModel) => x.id === notification.id,
      );
      category.notifications[notificationIndex] = notification;

      setNotificationCategories([...tempNotificationCategories]);
    },
    [notificationCategories],
  );

  const updateNotification = useCallback(
    (notification: NotificationModel, globalDateObj: GlobalDate) => {
      setLastScrollPosition();

      const tmp = overviewData!;
      const index = tmp.notifications.findIndex((x: NotificationModel) => x.id === notification.id);
      tmp.notifications[index] = notification;

      updateCategories(notification);
      setOverviewData({ ...tmp, globalDate: globalDateObj });
    },
    [overviewData, setOverviewData, updateCategories],
  );

  const sortNotifications = useCallback(
    (value: string) => {
      const tmp = overviewData!;
      const sorted: NotificationModel[] = sort(tmp.notifications, value);
      sessionStorage.setItem('c24-sort-1', value);
      setOverviewData({
        ...tmp,
        notifications: [...sorted],
      });
    },
    [overviewData, setOverviewData],
  );

  const updateStandingOrder = useCallback(
    (standingOrder: StandingOrderModel, globalDateObj: GlobalDate) => {
      setLastScrollPosition();

      const tmp = overviewData!;
      const index = tmp.standingOrders.findIndex(
        (x: StandingOrderModel) => x.id === standingOrder.id,
      );
      tmp.standingOrders[index] = standingOrder;

      setOverviewData({ ...tmp, globalDate: globalDateObj });
    },
    [overviewData, setOverviewData],
  );

  const updateClosingData = useCallback(
    (closingData: ClosingDataModel) => {
      setLastScrollPosition();

      setOverviewData({ ...overviewData!, closingData });
    },
    [setOverviewData, overviewData],
  );

  const initialProvider: IOverviewProvider = useMemo(
    () => ({
      overviewData,
      notificationCategories,
      fillOverviewData,
      resetSwitchingDates,
      sortNotifications,
      updateNotification: updateNotificationMock || updateNotification,
      updateClosingData: updateClosingDataMock || updateClosingData,
      updateStandingOrder: updateStandingOrderMock || updateStandingOrder,
    }),
    [
      overviewData,
      notificationCategories,
      fillOverviewData,
      resetSwitchingDates,
      sortNotifications,
      updateNotificationMock,
      updateNotification,
      updateClosingDataMock,
      updateClosingData,
      updateStandingOrderMock,
      updateStandingOrder,
    ],
  );

  return <OverviewContext.Provider value={initialProvider}>{children}</OverviewContext.Provider>;
};

export default OverviewProvider;
