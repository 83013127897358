import React, { useEffect, useRef, useState } from 'react';
import ServiceGateway from '../../../../../../services/ServiceGateway';
import { Frequency, ItemAction, ItemState } from '../../../../../../types/Overview/Common';
import { useKwsState } from '../../../../../../contexts/KwsStateContext/KwsStateContext';
import { StandingOrderDetailsModel } from '../../../../../../types/Overview/StandingOrderModel';
import Validators, { ValidationResult } from '../../../../../../utils/validators';
import { formatAmount, formatAmountReverse, formatDate } from '../../../../../../utils/helper';
import help from '../../../../../../assets/help.svg';
import Tooltip from '../../../../../Common/Tooltip';
import Checkbox from '../../../../../Common/Checkbox';
import DateInput from '../../../../../Common/DateInput';
import InfoBox from '../../../../../Common/InfoBox';
import TextInput from '../../../../../Common/TextInput';
import IbanInput from '../../../../../Common/IbanInput';
import SelectInput from '../../../../../Common/SelectInput';
import StickyButtons from '../../../../../Common/StickyButtons';
import ContactCard from '../../../../../Common/ContactCard';
import './index.scss';
import { TooltipStateEnum } from '../../../../../../contexts/TooltipStateContext/Tooltip.types';

type StandingOrderDetailTabProps = {
  standingOrderDetailState: StandingOrderDetailsModel;
  setStandingOrderDetailState: (item: StandingOrderDetailsModel) => void;
};

const StandingOrderDetailTab = ({
  standingOrderDetailState,
  setStandingOrderDetailState,
}: StandingOrderDetailTabProps) => {
  const { kwsState, setStandingOrderToProcess, standingOrderToProcess } = useKwsState();
  const { pageType } = standingOrderToProcess!;
  const firstRender = useRef(true);

  const [possibleFrequencies, setPossibleFrequencies] = useState<Frequency[]>([]);
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState<Record<string, ValidationResult>>({});

  const [firstSwitchingDate, setFirstSwitchingDate] = useState<string>(
    standingOrderDetailState.dates?.switchingDate,
  );

  const getPossibleFrequencies = async () => {
    const result = await ServiceGateway.getPossibleFrequencies(kwsState!.id);
    setPossibleFrequencies(result.data);
  };

  useEffect(() => {
    getPossibleFrequencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const sDate = standingOrderDetailState?.dates?.switchingDate;
    if (pageType === 'EDIT' && sDate && !firstRender.current) {
      ServiceGateway.firstExecutionDate(kwsState!.id, standingOrderDetailState.id, sDate).then(
        (res) =>
          setStandingOrderDetailState({
            ...standingOrderDetailState,
            dates: {
              ...standingOrderDetailState.dates,
              firstExecutionNewBankDate: res.data,
            },
          }),
      );
    }
    firstRender.current = false;
  }, [standingOrderDetailState?.dates?.switchingDate]);

  const submit = async () => {
    setLoading(true);
    try {
      const data = {
        state: standingOrderDetailState.state || ItemState.NOTIFY,
        action: standingOrderDetailState.action || ItemAction.MESSAGE,
        category: standingOrderDetailState.category || 'UNKNOWN',
        asap: standingOrderDetailState.dates?.asap,
        switchingDate: standingOrderDetailState.dates?.asap
          ? standingOrderDetailState.dates?.earliestSwitchingDate
          : standingOrderDetailState.dates?.switchingDate,
        frequency: standingOrderDetailState.frequency,
        recipientName: standingOrderDetailState.recipientName,
        recipientIban: standingOrderDetailState.recipientIban,
        amount: standingOrderDetailState.amount,
        description: standingOrderDetailState.description,
      };
      let result;
      if (pageType === 'EDIT') {
        result = await ServiceGateway.updateStandingOrder(kwsState!.id, {
          id: standingOrderDetailState.id,
          ...data,
        });
      } else {
        result = await ServiceGateway.createStandingOrder(kwsState!.id, data);
      }
      const isSuccess = result.status >= 200 && result.status < 300;
      if (isSuccess) {
        setStandingOrderToProcess(undefined);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        if (error.response?.data?.errors) {
          setValidationError({
            ...validationError,
            ...error.response.data.errors.reduce((acc: any, err: any) => {
              if (err.type === 'FIELD') {
                return {
                  ...acc,
                  [err.key]: { valid: false, message: err.message },
                };
              }
              return acc;
            }, {}),
          });
        }
      }
    }
    setLoading(false);
  };

  const goBack = () => {
    setStandingOrderToProcess(undefined);
  };

  const onCheck = (checked: boolean) => {
    if (checked) {
      setFirstSwitchingDate(standingOrderDetailState.dates.switchingDate);
    }
    setValidationError((validations) => {
      const v = {
        ...validations,
        switchingDate: { valid: true },
      };
      return v;
    });

    setStandingOrderDetailState({
      ...standingOrderDetailState,
      dates: {
        ...standingOrderDetailState.dates,
        asap: checked,
        switchingDate: checked
          ? standingOrderDetailState.dates?.earliestSwitchingDate
          : firstSwitchingDate,
      },
    });
  };

  const errorCheck = !(
    standingOrderDetailState.dates?.switchingDate &&
    standingOrderDetailState.recipientName &&
    standingOrderDetailState.recipientIban &&
    standingOrderDetailState.amount &&
    // standingOrderDetailState.description &&
    standingOrderDetailState.frequency &&
    Object.values(validationError).every((item) => item.valid)
  );

  const renderInfoBoxData = () =>
    standingOrderDetailState.dates?.originalEndExecutionDate ? (
      <>
        <p>
          {`Erste Ausführung vom neuen Girokonto zum ${formatDate(
            standingOrderDetailState.dates?.firstExecutionNewBankDate,
          )}`}
        </p>
        <p>
          {`Letzte Ausführung vom neuen Girokonto zum ${formatDate(
            standingOrderDetailState.dates.originalEndExecutionDate,
          )}`}
        </p>
      </>
    ) : (
      <p>
        {`Erste Ausführung vom neuen Girokonto zum ${formatDate(
          standingOrderDetailState.dates?.firstExecutionNewBankDate,
        )}`}
      </p>
    );

  return (
    <div className='standing-order-details-tab'>
      <div className='form-container'>
        {pageType === 'ADD' && (
          <div className='description add'>
            Bitte tragen Sie die Daten des Dauerauftrags ein, den wir zusätzlich einrichten sollen
          </div>
        )}

        {/* Switching date */}
        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>
              Für welches Datum soll der Dauerauftrag eingerichtet werden?
            </div>
            <Tooltip tooltipSectionName={TooltipStateEnum.SWITCHING_DATE} hasBackground>
              <p>
                Das Einrichtungsdatum des Dauerauftrags können Sie frei wählen. Die erste Ausführung
                wird automatisch berechnet und angezeigt. Sollte das Datum des Dauerauftrages an
                einem Wochenendtag oder an einem Feiertag fallen, wird er am nächsten Bankarbeitstag
                ausgeführt.
              </p>
            </Tooltip>
          </div>
          <div className='form-input switching-date'>
            <div className='asap-row'>
              <Checkbox
                onChange={onCheck}
                checked={standingOrderDetailState.dates?.asap}
                inputId='asap-checkbox'
              />
              <div className='asap-text'>Schnellstmöglich</div>
            </div>
            <DateInput
              value={standingOrderDetailState.dates?.switchingDate}
              onChange={(v) =>
                setStandingOrderDetailState({
                  ...standingOrderDetailState,
                  dates: {
                    ...standingOrderDetailState.dates,
                    switchingDate: v,
                  },
                })
              }
              validation={validationError.switchingDate}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  switchingDate: e,
                }))
              }
              disabled={standingOrderDetailState.dates?.asap}
            />
          </div>

          {pageType === 'EDIT' && standingOrderDetailState.dates?.switchingDate && (
            <InfoBox type='info'>{renderInfoBoxData()}</InfoBox>
          )}
        </div>

        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>An wen möchten Sie überweisen?</div>
          </div>
          <div className='form-input'>
            <TextInput
              className='w-full'
              value={standingOrderDetailState.recipientName}
              onChange={(v) =>
                setStandingOrderDetailState({
                  ...standingOrderDetailState,
                  recipientName: v,
                })
              }
              placeholder='Empfänger'
              validator={Validators.moreThan2()}
              icon={help}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  recipientName: e,
                }))
              }
              validation={validationError.recipientName}
            />
          </div>
          <div className='form-input'>
            <IbanInput
              className='w-full'
              value={standingOrderDetailState.recipientIban}
              onChange={(v) =>
                setStandingOrderDetailState({
                  ...standingOrderDetailState,
                  recipientIban: v,
                })
              }
              placeholder='IBAN'
              validator={Validators.germanIbanOrAnyForeign()}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  recipientIban: e,
                }))
              }
              validation={validationError.recipientIban}
            />
          </div>
        </div>

        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Welchen Betrag möchten Sie überweisen?</div>
          </div>
          <div className='form-input'>
            <TextInput
              className='w-33p'
              value={formatAmount(standingOrderDetailState.amount?.toString())}
              placeholder='Betrag'
              inputMode='decimal'
              inputRegex={/[^0-9,]/}
              onBlurChange={(v) =>
                setStandingOrderDetailState({
                  ...standingOrderDetailState,
                  amount: formatAmountReverse(v),
                })
              }
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  amount: e,
                }))
              }
              validation={validationError.amount}
              validator={Validators.empty()}
            />
            <div className='text'>Euro</div>
          </div>
        </div>

        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Möchten Sie einen Verwendungszweck hinzufügen?</div>
          </div>
          <div className='form-input'>
            <TextInput
              className='w-full'
              value={standingOrderDetailState.description}
              onChange={(v) =>
                setStandingOrderDetailState({
                  ...standingOrderDetailState,
                  description: v ? v.replace(/&/g, '+') : '',
                })
              }
              inputRegex={/[^a-zA-Z\d/?:().,'\-+& ]/g}
              placeholder='Verwendungszweck'
              validator={Validators.moreThan2orNull()}
              icon={help}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  description: e,
                }))
              }
              validation={validationError.description}
              optional
            />
          </div>
        </div>

        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>
              In welchem Intervall soll der Dauerauftrag ausgeführt werden?
            </div>
          </div>
          <div className='form-input'>
            <SelectInput
              className='w-full'
              placeholder='Ausführungsintervall'
              onChange={(v) =>
                setStandingOrderDetailState({
                  ...standingOrderDetailState,
                  frequency: v as Frequency,
                })
              }
              value={standingOrderDetailState.frequency}
              icon={help}
              options={possibleFrequencies.map((pf: any) => ({
                value: pf.name,
                placeholder: pf.locale,
              }))}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  frequency: e,
                }))
              }
              validator={Validators.empty()}
              validation={validationError.frequency}
              emptyOptionExist
              emptyOptionEnabled
            />
          </div>
        </div>
      </div>

      <StickyButtons
        isDisabled={errorCheck}
        isLoading={loading}
        hasError={errorCheck}
        proceedButton={{ label: 'Dauerauftrag speichern', onClick: submit }}
        cancelButton={{ label: 'Änderungen verwerfen', onCancel: goBack }}
      />

      <ContactCard />
    </div>
  );
};

export default StandingOrderDetailTab;
