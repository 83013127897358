import React, { useEffect, useRef, useState } from 'react';
import isEqual from 'lodash.isequal';
import { TextInputState } from '../../../types/Input';
import { ValidationResult, Validator } from '../../../utils/validators';
import './index.scss';
import Spinner from '../Spinner';

interface AutoCompleteProps<T> {
  disabled?: boolean;
  disabledJustInput?: boolean;
  placeholder: string;
  icon?: any;
  className?: string;
  value: string;
  onChange: (v: string) => void;
  onBlurChange?: (value: string) => void;
  onFocusChange?: (focus: boolean) => void;
  disableErrorText?: boolean;
  inputRef?: any;
  inputRegex?: RegExp;
  inputMode?: any;
  onClick?: () => void;
  onResultClick?: (value: T) => void;
  onKeyDown?: (event: any) => void;
  optional?: boolean;
  optionalText?: string;
  result?: T[];
  loading?: boolean;
  onClickOutside?: () => void;
  renderDisplayValue: (result: T) => JSX.Element;
  isSearchInputSelectable?: boolean;
  explanationText?: string;
  searchMinLength?: number;
  errorMessage?: string;
}

const initialState = {
  focus: false,
  value: '',
};

const AutoComplete = <T,>(props: AutoCompleteProps<T>) => {
  const {
    disabled,
    disableErrorText,
    disabledJustInput,
    placeholder,
    value,
    icon,
    className,
    onChange,
    onBlurChange,
    onFocusChange,
    onClick,
    onKeyDown,
    onResultClick,
    optional,
    optionalText,
    inputMode,
    inputRef,
    inputRegex,
    result,
    loading,
    onClickOutside,
    renderDisplayValue,
    isSearchInputSelectable,
    explanationText,
    errorMessage,
    searchMinLength = 3,
  } = props;
  const [state, setState] = useState(initialState);
  const componentRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
      if (onClickOutside && result && result.length > 0) onClickOutside();
    }
  };

  const handleChange = (e: any) => {
    const valueV = e.currentTarget.value || '';
    const regexValue = inputRegex ? value.replace(inputRegex, '') : valueV;
    setState({
      ...state,
      value: regexValue || null,
    });
    if (onChange) {
      onChange(regexValue || null);
    }
  };

  useEffect(() => {
    if (typeof value !== 'undefined') {
      setState((oldState) => ({
        ...oldState,
        value,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [result]);

  const isSelectableInputShown =
    isSearchInputSelectable && value && value.length >= searchMinLength && !loading;

  return (
    <div
      id='autocomplete'
      className={`text-input autocomplete ${errorMessage ? 'error ' : ''}${
        state.focus ? 'focus ' : ''
      }${disabled ? 'disabled ' : ''}${className ?? ''}`}
      data-testid='auto-complete-component'
      ref={componentRef}
    >
      <div className={`inner${(result && result.length > 0) || loading ? ' full' : ' empty'}`}>
        <input
          className={state.value ? 'has-value' : ''}
          type='text'
          inputMode={inputMode ?? 'text'}
          value={state.value ?? ''}
          data-testid='auto-complete'
          onChange={handleChange}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
          onKeyDown={(v) => {
            if (onKeyDown) onKeyDown(v);
          }}
          onFocus={() => {
            if (onFocusChange) {
              onFocusChange(true);
            }
            setState({ ...state, focus: true });
          }}
          onBlur={() => {
            setState({ ...state, focus: false });
            if (onFocusChange) {
              onFocusChange(false);
            }
            if (onBlurChange) {
              onBlurChange(state.value);
            }
          }}
          disabled={disabled}
          readOnly={disabledJustInput}
          ref={inputRef}
        />
        <div className='floating-label'>{`${placeholder}${
          optional && !state.value && !state.focus ? ` ${optionalText ?? '(optional)'}` : ''
        }`}</div>
        {icon && (
          <div className='append-inner'>
            <img className='help' src={icon} alt='' />
          </div>
        )}
      </div>
      <div
        className={`result-box${
          (result && result.length > 0) || isSelectableInputShown ? ' full' : ' empty'
        }`}
      >
        {result &&
          !loading &&
          result.map((pb: T, i: number) => (
            <div
              data-testid={`bank-${i}`}
              onClick={() => {
                if (onResultClick) {
                  onResultClick(pb);
                }
              }}
              key={`pb-${i}`}
            >
              {renderDisplayValue(pb)}
            </div>
          ))}
        {isSelectableInputShown && (
          <div
            className='possible-item'
            onMouseDown={(event) => {
              event.preventDefault();
            }}
            onClick={onClick}
          >
            {value} {`(${explanationText})` || ''}
          </div>
        )}
        {loading && <Spinner />}
      </div>
      {errorMessage && !disableErrorText && <div className='error'> {errorMessage} </div>}
    </div>
  );
};

export default AutoComplete;
